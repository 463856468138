let ValidateService = function(
    ApiRequest
) {
    let uriPrefix = '/validate/';

    return new(function() {
        this.storeBookingCustomItem = function(values) {
            return ApiRequest.post(
                uriPrefix + 'booking-custom-item/store', values
            );
        };

        this.updateBookingCustomItem = function(id, values) {
            return ApiRequest.post(
                uriPrefix + 'booking-custom-item/' + id + '/update', values
            );
        };

        this.storeGiftcardCustomItem = function(values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-custom-item/store', values
            );
        };

        this.updateGiftcardCustomItem = function(id, values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-custom-item/' + id + '/update', values
            );
        };

        this.storeGiftCardAccommodation = function(values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-accommodation/store', values
            );
        }

        this.updateGiftCardAccommodation = function(id, values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-accommodation/' + id + '/update', values
            );
        }

        this.storeGiftCardActivity = function(values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-activity/store', values
            );
        }

        this.updateGiftCardActivity = function(id, values) {
            return ApiRequest.post(
                uriPrefix + 'gift-card-activity/' + id + '/update', values
            );
        }
    });
};

module.exports = [
    'ApiRequest',
    ValidateService
];