let ModalQuestionComponent = function() {
    let $ctrl = this;

    $ctrl.class = [
        'modal-sm'
    ];

    $ctrl.$onInit = () => {
        $ctrl.question = $ctrl.modal.scope;

        $ctrl.onClick = (button = null) => {
            if ($ctrl.question.autoClose) {
                $ctrl.close();
            }

            if (typeof $ctrl.question.submit == 'function') {
                $ctrl.question.submit($ctrl, button);
            }
        };

        $ctrl.dismiss = () => $ctrl.onClick(null);
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        ModalQuestionComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-question.html';
    }
};