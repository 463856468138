let vars_text = {
    first_name: 'Client first name',
    last_name: 'Client last name',
    full_name: 'Client full name',
    count_gift_cards: 'Count gift cards the client has bought.',

    accommodation_name: 'Selected acomodation name',
    activity_name: 'Selected activity name',
    guests: 'Count guests',
    nights: 'Count nights',

    days_to_pay: 'Count days left to pay for the reservation.',
    due_date: 'Last date to pay for the reservation',
    reservation_date: 'Date of when the earliest accommodation starts',

    button_link: 'Link of the button'
};

module.exports = {
    'admin_send_invoice_mail-regular': {
        title: 'Admin send regular invoice to client',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'admin_send_invoice_mail-refund': {
        title: 'Admin send refund invoice to client',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },  
    'admin_send_invoice_mail-bye': {
        title: 'Admin send bye invoice to client',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'gift_cards_bought': {
        title: 'A giftcard was bought by client',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            },
            activity_item: {
                label: 'Activity item',
                vars: {
                    activity_name: vars_text.activity_name,
                    guests: vars_text.guests,
                }
            },
            accommodation_item: {
                label: 'Accommodation item',
                vars: {
                    accommodation_name: vars_text.accommodation_name,
                    guests: vars_text.guests,
                    nights: vars_text.nights,
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            }
        }
    },
    'gift_cards_generated': {
        title: 'A giftcard was generated',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            },
            activity_item: {
                label: 'Activity item',
                vars: {
                    activity_name: vars_text.activity_name,
                    guests: vars_text.guests,
                }
            },
            accommodation_item: {
                label: 'Accommodation item',
                vars: {
                    accommodation_name: vars_text.accommodation_name,
                    guests: vars_text.guests,
                    nights: vars_text.nights,
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    count_gift_cards: vars_text.count_gift_cards,
                }
            }
        }
    },
    'booking_canceled': {
        title: 'A booking was canceled (by admin)',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date
                }
            }
        }
    }, 
    'booking_unpaid_canceled': {
        title: 'A booking was canceled due to reservation payment expiration',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date
                }
            }
        }
    }, 
    'booking_created-unpaid': {
        title: 'Booking created (unpaid)',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'booking_created-unpaid_late': {
        title: 'Booking created (unpaid, starts too close to reservation date thus require immediate payment)',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    hours_to_pay: vars_text.days_to_pay,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    hours_to_pay: vars_text.days_to_pay,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    hours_to_pay: vars_text.days_to_pay,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'booking_created-paid_bye': {
        title: 'Booking created (paid bye)',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'booking_expiring': {
        title: 'A booking is expiring (reminder)',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    }, 
    'booking_reservation_paid': {
        title: 'Booking reservation is successfully paid and confirmed',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },
    'booking_created-direct_payment': {
        title: 'Booking created direct payment',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },
    'booking_created-reservation_payment': {
        title: 'Booking created reservation',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },
    'booking_created-prepayment': {
        title: 'Booking created prepayment',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },
    'booking_expired_5_days': {
        title: 'Booking invoice due date passed by 5 days',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date,
                    button_link: vars_text.button_link
                }
            }
        }
    },
    'booking_expired_1_day': {
        title: 'Booking invoice due date passed by 1 day',
        fields: {
            description: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date 
                }
            },
            description_2: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date
                }
            },
            footer_information: {
                label: null,
                vars: {
                    first_name: vars_text.first_name,
                    last_name: vars_text.last_name,
                    full_name: vars_text.full_name,
                    days_to_pay: vars_text.days_to_pay,
                    due_date: vars_text.due_date,
                    reservation_date: vars_text.reservation_date
                }
            }
        }
    },
};