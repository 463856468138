let AccommodationTypeService = function(ApiRequest) {
    let uriPrefix = '/accommodation-types';

    return new (function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };
    });
};

module.exports = [
    'ApiRequest',
    AccommodationTypeService
];