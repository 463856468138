window._ = require('lodash');

try {
    require('bootstrap-sass');
} catch (e) {}

$.prototype.ajaxAction = function(_args) {
    var self = this;
    var coll = [];

    if (self.length === 0)
        return;

    var ajaxAction = function (_self) {

        var _target_block = $(_self.attr('target-block'));

        var url = _self.attr('href');
        var type = _self.attr('method') || 'POST';

        var _submited = false;

        // send data to server through ajax request
        var sendData = function () {
            $.ajax({
                url: url,
                type: type
            }).done(function (response) {
                _submited = false;
                proceedErrors([]);
                proceedResponse(response);
            }).fail(function (response) {
                _submited = false;

                if(response.status == 422){
                    var errors = response.responseJSON,
                        errors_text = '';

                    $.each(errors, function (key, value) {
                        errors_text += value.toString() + "\n";
                    });

                    alert(errors);
                }

                proceedErrors(JSON.parse(response.responseText));
            });
        };

        // proceed ajax response
        var proceedResponse = function (resp) {
            if (!resp || typeof resp.status == 'undefined' || resp.status != 'OK')
                return proceedResponseError();

            proceedResponseSuccess(resp);
        };

        var proceedResponseSuccess = function (resp) {
            _self.addClass('success');

            if (resp.action == 'reload_page')
            {
                window.location.reload();
            }
            else if (resp.action == 'load_content')
            {
                _target_block.html(resp.data);
            }
            if (resp.action == 'callback' || resp.callback) {
                window['deleteRowFromTable'](_self, resp);
                window[$(_self).data('callback')](_self, resp);
            }
        };

        var proceedResponseError = function () {
        };

        // proceed ajax response
        var proceedErrors = function (response) {

            _target_block.empty();
        };

        // submit form
        var submitForm = function () {
            _submited = true;
            sendData();
        };

        // bind events
        var bind = function () {
            _self.unbind('click').bind('click', function (e) {
                if (e && (e.preventDefault() & e.stopPropagation()));

                if (!_submited)
                    submitForm();
            });
        };

        bind();
    };

    for (var i = self.length - 1; i >= 0; i--) {
        coll.push(new ajaxAction($(self[i])));
    }
};

$('.ajaxAction').ajaxAction();


$(document).ready(function () {
    // Change status active in admin
    $(document).on('change', 'input.change-active-admin[type=checkbox]', function () {
        var el = $(this),
            active = 0,
            link_update = el.data('ownerId');

        if ($(this).is(':checked')) {
            active = 1;
        }
        $.post(
            link_update, {
                active: active
            });
    });

    $(document).on('change', 'input[type="checkbox"].entry-chk-box', function () {
        var count_checked = $('input[type="checkbox"].entry-chk-box:checked').length;
        if (count_checked < 1) {
            count_checked = '';
        }
        $('span#display_checked_count').html(count_checked);
    });

    $(document).on('change', 'input.check-all', function (e) {
        let count_checked = 0;
        let target = $($(this).attr('target'));
        let selector = 'input[type="checkbox"].entry-chk-box';
        let checkboxes = $(target.length > 0 ? target.find(selector) : selector);


        if ($(this).is(':checked')) {
            checkboxes.each(function () {
                $(this).prop("checked", true);
                count_checked++;
            });
        } else {
            checkboxes.prop('checked', false);
        }

        $('span#display_checked_count').html(
            count_checked < 1 ? count_checked : ''
        );
    });

    $(document).on('click', '.modalFormToggle', function (e) {
        e.preventDefault();
        var modal = $('#modalForm');
        var el = $(this);

        modal.data('href', $(el).attr('href'));
        modal.data('callback', el.data('callback'));

        reloadModal(modal);

        return false; // prevent other actions
    });

    $(document).on('click', '#showPreviewInModal', function (e) {
        e.preventDefault();
        var modal = $('#newsletterModalForm');

        modal.modal('show');

        modal.find('.modal-body').html('');
        modal.find('.modal-title').html('');

        modal.find('.modal-title').text($('input[name="name"]').val());
        modal.find('.modal-body').html($('textarea[name="body"]').val());
    });

    $(document).on("click", 'span.sort', function () {
        var el = $(this),
            input_sort =  $('input#sort'),
            form = $("#form-entry"),
            input_direction =  $('input#direction');

        if(!el.hasClass('asc') && !el.hasClass('desc')) {
            el.addClass('asc');
            input_direction.attr('value','asc');
        }else if(el.hasClass('asc')) {
            el.removeClass('asc');
            el.addClass('desc');
            input_direction.attr('value','desc');
        }else if(el.hasClass('desc')){
            el.removeClass('desc');
            el.addClass('asc');
            input_direction.attr('value','asc');
        }

        input_sort.attr('value', el.data('attribute'));

        form.submit();
    });


    $(document).on('change', '#component_class', function () {

        var el = $(this),
            url = el.data('url'),
            componentId = $('#component_id');

        componentId.empty();

        $.post(url, {component: el.val()})
            .done(function (response) {
                if(response.status === 'OK') {
                    componentId.empty();

                    var array = response.list;
                    if (array != '') {
                        for (i in array) {
                            componentId.append("<option value='" + i + "'>" + array[i] + "</option>");
                        }

                    }
                }

            });
    });

});

function reloadModal(modal) {
    // сначала показать форму
    modal.modal('show');

    // потом очистить содержимое
    modal.find('.modal-body').html('');
    modal.find('.modal-locked').html('');
    modal.find('.modal-title').html('');

    // потом загрузить содержимое по ajax
    $.get($(modal).data('href'), function (data) {
        var content;

        // если ответ сервера JSON - разобрать его
        if (jQuery.type(data) === 'object') {

            content = data.content;

            if (data.modalTitle !== 'undefined') {
                modal.find('.modal-title').html(data.modalTitle);
            }

        } else {
            content = data;
        }

        modal.find('.modal-body').html(content);
        onModalLoad(modal);
    });
}

function onModalLoad(modal) {
    if ($(modal).data('noModalSubmit') || $(modal).find('form').hasClass('no-modal-submit')) {
        $(modal).find('#modalSaveButton').hide();
    } else {
        $(modal).find('#modalSaveButton').show().removeAttr('disabled');
    }
}

$(document).on('click', '#modalSaveButton', function () {
    submitModalForm();
});

$(document).on('keyup keypress','#modalForm form', function(e) {

    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
        e.preventDefault();
        return false;
    }
});

function submitModalForm() {

    beforeModalFormSubmit();

    var form = $('.modal-body').find('form');

    if ($(form).hasClass('optionFormSubmitNoAjaxClass')) {
        $(form).submit();
        return true;
    }

    $.ajax({
        type: "POST",
        url: form.attr('action'),
        data: form.serialize()
    }).done(function (data) {
        afterModalFormSubmit();
        processModalResponse(data);
    }).fail(function (data) {

        if (data.status == 422) {

            var errors = data.responseJSON.errors,
                errors_text = [];

            $.each(errors, function (key, value) {
                errors_text.push(value.toString());
            });

            errors_text.join("<br>");

            pushNotifications.push(
                'danger',
                'close-circle-outline',
                errors_text,
                5000
            );

            afterModalFormSubmit();
        }
        else {
            afterModalFormSubmit();
            console.log(data.errors);
        }
    });
}

function beforeModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').attr('disabled', 'disabled');
}

function afterModalFormSubmit() {
    var modal = $('#modalForm');
    $(modal).find('#modalSaveButton').show().removeAttr('disabled');
}

function processModalResponse(data) {
    var modal = $('#modalForm');
    modal.find('.form-control-message').remove();

    var isJson = true;
    if (data.responseText) {
        data = data.responseText;
    }
    if (jQuery.type(data) === 'string') {
        try {
            data = jQuery.parseJSON(data);
        }
        catch (err) {
            console.log(err);
            isJson = false;
        }
    }

    if (isJson) {

        if (data.status == 'fail') {
            return false;
        } else if (data.status == 'ERROR') {
            if(data.message != '') {

                pushNotifications.push(
                    'danger',
                    'close-circle-outline',
                    data.message,
                    5000
                );

                afterModalFormSubmit();
            }

            return false;
        }

        if (data.action == 'reload_page') {

            window.location.reload();

        } else if (data.action == 'callback') {

            // выполнить пользовательскую функцию, расположенную в форме модального окна
            window[data.callback](data);
        } else if (data.action == 'callback_static') {

            // выполнить пользовательскую функцию, расположенную в форме
            window[data.actionCallback](data.actionCallbackParams);
        }

        if (data.action == 'reload_modal') {

            if (data.reload_action) {
                $(modal).data('href', data.reload_action);
            }
            reloadModal($(modal));

        } else if (data.status == 'OK') {
            if ((modal.data('bs.modal') || {}).isShown) {
                $(modal).modal('hide');
            }
        }

        return (data.status == 'ok');
    }
}

$(document).on('shown.bs.modal', '#modalForm', function () {

    /*$("[ui-jq]").each(function(){
        var self = $(this);
        var options = eval('[' + self.attr('ui-options') + ']');

        if ($.isPlainObject(options[0])) {
            options[0] = $.extend({}, options[0]);
        }

        uiLoad.load(jp_config[self.attr('ui-jq')]).then( function(){
            self[self.attr('ui-jq')].apply(self, options);
        });

    });*/
});

$(document).on('click', '#scroll-top-btn', function(e) {
    e.preventDefault();
    var body = $("html, body");
    body.stop().animate({scrollTop:0}, 500, 'swing');
});

/* $(function() {
    $(".form_datetime").datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        todayHighlight: true
    });
});
 */
$( function() {
    /* $(".date-picker").datepicker({
        format: 'dd-mm-yyyy'
    }); */

    /* $(".date-picker-btn").click(function() {
        $(this).closest('.form-group').find(".date-picker").datepicker("show");
    }); */

    // $('.time-picker').timepicker({ 'timeFormat': 'H:i' });

    $(document).on('change', '.select-show-hide', function () {
        var el = $(this),
            hideEl = el.data('hide'),
            showEl = el.data('show');

        if(el.val() != '0'){
            if(typeof hideEl !== 'underfined')
                $(hideEl).hide();
            if(typeof showEl !== 'underfined')
                $(showEl).show();
        }else{
            if(typeof hideEl !== 'underfined')
                $(hideEl).show();
            if(typeof showEl !== 'underfined')
                $(showEl).hide();
        }
    });

    $('.select-show-hide').trigger('change');

    $(document).on('change', '.show-hide-elem', function () {
        var el = $(this),
            target = $(el.data('target'));

        if(el.is(':checked'))
            target.show();
        else
            target.hide();
    });

    $(document).on('click', '.need-confirm', function(event) {
        event.preventDefault();
        
        let message = $(this).data('message');

        if (confirm(message ? message : "Are you sure you want to delete?")) {
            window.location = $(this).attr('href');
        }
    });
});

// for add button
$(document).on('click', '.add-btn', function (e) {
    e.preventDefault();
    var targetBlockSelector = $(this).data('target'),
        targetBlock = $('#' + targetBlockSelector),
        templateSelector = targetBlockSelector + '-template',
        blocks = targetBlockSelector + '-group';

    var tmpl = document.getElementById(templateSelector).content.cloneNode(true);
    var textHead = $(tmpl).find('.' + targetBlockSelector + '-head');
    var blockCount = targetBlock.find('.' + blocks).length + 1;
    textHead.text($.trim(textHead.text()) + blockCount);

    if($(tmpl).find('.image-input').length){

        $(tmpl).find('.image-input').attr('id', $(tmpl).find('.image-input').attr('id') + blockCount);
        $(tmpl).find('.popup_selector').attr('data-inputid', $(tmpl).find('.popup_selector').attr('data-inputid') + blockCount);
    }

    targetBlock.append(tmpl);

    /* $(".date-picker").datepicker({
        format: 'dd-mm-yyyy'
    });

    $(".date-picker-btn").click(function() {
        $(this).closest('.form-group').find(".date-picker").datepicker("show");
    }); */
});

// for delete button
$(document).on('click', '.del-btn', function (e) {
    e.preventDefault();
    if (confirm("Are you sure?")) {
        $(this).closest($(this).data('target')).remove();
    }
});


$(document).on('submit', 'form', function (e) {
    if($(this).find('select option[value="delete"]:selected').length || $(this).find('select option[value="cancel"]:selected').length){
        return window.confirm('Are you sure?');
    }
});



/* function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
} */

/* 
window.pushNotifications = new(function($node) {
    $node = $('[push-notifications]');

    var self = this;
    var notes = $node.find('.notification');
    var template = $node.find('.template');
    var shownNotes = [];
    var maxCount = 4;

    self.bind = function($note) {
        if (!$note.hasClass('shown')) {
            $note.addClass('shown');
        }

        var note = {
            note: $note
        };

        note.timeout = setTimeout(function() {
            self.deleteNotification(note);
        }, parseInt(note.note.attr('expire')) * 1);

        shownNotes.push(note);

        note.note.find('.close').unbind('click').bind('click', function() {
            self.deleteNotification(note);
        });

        if (shownNotes.length > maxCount) {
            var _note = shownNotes[0];

            self.deleteNotification(_note);
        }
    };

    self.deleteNotification = function(note) {
        clearTimeout(note.timeout);
        note.note.removeClass('shown');
        shownNotes.splice(shownNotes.indexOf(note), 1);

        setTimeout(function() {
            note.note.remove();
        }, 450);
    };

    self.init = function() {
        notes.each(function(index, note) {
            self.bind($(note));
        });
    };

    self.push = function(type, icon, text, timeout) {
        var notification = template.clone();

        notification.removeClass('template');
        notification.addClass('notification');
        notification.addClass('notification-' + type);

        notification.removeAttr('hidden');
        notification.attr('expire', timeout);
        notification.find('.text').html(text);
        notification.find('.icon').addClass('mdi mdi-' + icon);

        $node.find('.inner').prepend(notification);

        setTimeout(function() {
            self.bind(notification);
        }, 100);
    };

    self.init();
})();


$('[toggle-sidebar]').unbind('click').bind('click', function(e) {
    e && e.preventDefault() && e.stopPropagation();

    var $body = $('body');

    if ($body.hasClass('sidebar-opened')) {
        $body.removeClass('sidebar-opened');
    } else {
        $body.addClass('sidebar-opened');
    }
});

$('[toggle-navbar]').unbind('click').bind('click', function(e) {
    e && e.preventDefault() && e.stopPropagation();

    var $body = $('body');

    if ($body.hasClass('navbar-opened')) {
        $body.removeClass('navbar-opened');
    } else {
        $body.addClass('navbar-opened');
    }
});

$('.block-aside .menu .menu-item .submenu-toggle').unbind().bind('click', function(e) {
    e && e.preventDefault() & e.stopPropagation();

    $(this).parent().closest('.menu-item').toggleClass('open');
    $('.block-aside .menu').nanoScroller();
});

$('.block-layout .menu-submenu .menu-item.active').each(function() {
    var target_parent = $(this).parent().closest('.menu-item');

    if (!target_parent.hasClass('open'))
        target_parent.find('.submenu-toggle').click();
});
 */