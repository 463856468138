let ModalGiftCardActivityEditComponent = function(
    ValidateService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.form = false;
    $ctrl.custom_item = false;

    $ctrl.$onInit = () => {
        $ctrl.types = $ctrl.modal.scope.types;
        $ctrl.isUsed = $ctrl.modal.scope.isUsed;
        $ctrl.activities = $ctrl.modal.scope.activities;
        $ctrl.activity = JSON.parse(JSON.stringify(
            $ctrl.modal.scope.activity
        ));

        if ($ctrl.activity.amount) {
            $ctrl.activity.amount = parseFloat($ctrl.activity.amount);
        }

        $ctrl.form = FormBuilderService.build($ctrl.activity, form => {
            let promise;

            if (form.values.id) {
                promise = ValidateService.updateGiftCardActivity(
                    form.values.id,
                    form.values
                );
            } else {
                promise = ValidateService.storeGiftCardActivity(form.values);
            }

            form.values.activity = $ctrl.activities.filter(
                item => item.id == form.values.activity_id
            )[0];

            promise.then((res) => {
                $ctrl.modal.scope.submit(form.values);
                $ctrl.close();
            }, res => {
                form.errors = res.data.errors;
                form.unlock();
            });
        }, true);
    };


    $ctrl.$onDestroy = () => {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'ValidateService',
        'FormBuilderService',
        ModalGiftCardActivityEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-gift-card-activity-item-edit.html';
    }
};