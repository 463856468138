let AccommodationGroupService = function(ApiRequest) {
    let uriPrefix = '/accommodation-groups';

    return new(function() {
        this.list = function(values) {
            return ApiRequest.get(uriPrefix, values);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                values
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                values
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            let data = JSON.parse(JSON.stringify(apiResource));

            return data;
        };
    });
};

module.exports = [
    'ApiRequest',
    AccommodationGroupService
];