let TranslationEditComponent = function(
    LanguageService,
    FormBuilderService,
    TranslationService,
    PushNotificationsService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';

    $ctrl.loadEntry = (data) => {
        $ctrl.media = data.photo;

        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.i18nId) {
                $ctrl.updateEntry($ctrl.i18nId, form);
            } else {
                $ctrl.storeEntry(form);
            }
        });
    };

    $ctrl.storeEntry = (form) => {
        TranslationService.store(
            Object.assign({}, form.values, {
                locale: $ctrl.language
            })).then((res) => {
            let url = '/admin/i18n/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.form-error').closest('.form-group').offset().top
                }, 1500);
            }, 100);
        });
    };

    $ctrl.updateEntry = (id, form) => {
        TranslationService.update(id, Object.assign({}, form.values, {
            locale: $ctrl.language
        })).then((res) => {
            let url = '/admin/i18n/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            $ctrl.i18nId = res.data.data.id;

            if (history) {
                history.pushState({
                    urlPath: url
                }, '', url);
            }
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.$onInit = function() {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;

            if ($ctrl.i18nId) {
                return TranslationService.read(
                    $ctrl.i18nId
                ).then(res => $ctrl.loadEntry(
                    TranslationService.apiResourceToForm(res.data.data)
                ), console.error);
            }

            $ctrl.loadEntry({
                translations: {
                    nl: {
                        text: ''
                    }
                },
            });
        });
    };
};

module.exports = {
    bindings: {
        'i18nId': '<'
    },
    controller: [
        'LanguageService',
        'FormBuilderService',
        'TranslationService',
        'PushNotificationsService',
        TranslationEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/i18n-edit.html'
};