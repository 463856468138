let I18nEmailsEditComponent = function(
    ModalService,
    LanguageService,
    TranslationService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';
    $ctrl.items = [];
    $ctrl.i18n = [];

    $ctrl.translations = TranslationService.appTranslations('mails');

    $ctrl.editEmail = (groupKey) => {
        ModalService.open('i18nEmailsEdit', {
            groupKey: groupKey
        });
    };

    $ctrl.$onInit = function() {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;

            TranslationService.list({
                'group': 'mails'
            }).then(res => {
                $ctrl.i18n = res.data.data;
                $ctrl.items = res.data.data.reduce((coll, item) => {
                    if (coll.indexOf(item.namespace) == -1) {
                        coll.push(item.namespace);
                    }

                    return coll;
                }, []).map(item => ({
                    key: item,
                    value: $ctrl.translations[item] ? $ctrl.translations[item].title : item,
                }));
            });
        });
    };
};

module.exports = {
    bindings: {
        'i18nId': '<'
    },
    controller: [
        'ModalService',
        'LanguageService',
        'TranslationService',
        I18nEmailsEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/i18n-emails-edit.html'
};