let MediaService = function(
    ApiRequest
) {
    let uriPrefix = '/medias';

    return new(function() {
        this.list = function(values) {
            return ApiRequest.get(
                uriPrefix,
                values
            );
        };

        this.store = function(type, file) {
            let formData = new FormData();

            formData.append('file', file, file['name']);
            formData.append('type', type);

            return ApiRequest.post(uriPrefix, formData, {
                'Content-Type': undefined
            });
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.delete = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                values
            );
        };

        this.configs = () => {
            return ApiRequest.get(
                uriPrefix + '/configs'
            );
        };
    });
};

module.exports = [
    'ApiRequest',
    MediaService
];