let DayOffService = function(
    DateService,
    ApiRequest
) {
    let uriPrefix = '/day-offs';

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            return JSON.parse(JSON.stringify(formData));
        };

        this.apiResourceToForm = function(apiResource) {
            return apiResource;
        };
    });
};

module.exports = [
    'DateService',
    'ApiRequest',
    DayOffService
];