let moment = require('moment');

let ModalBookingActivityEditComponent = function(
    FormBuilderService,
    BookingService
) {
    let $ctrl = this;
    let scope;

    $ctrl.dateFormat = 'dd-MM-yyyy'
    $ctrl.errorKeyPreset = 'activities';
    $ctrl.errorKey;

    $ctrl.maxDate = moment().add(3, 'months').toDate().toDateString();
    $ctrl.peopleOptions = [];

    $ctrl.changeActivity = (id) => {
        let activity = $ctrl.activities.filter(
            activity => activity.id == id
        )[0] || null;

        $ctrl.activity = activity;
        
        $ctrl.peopleOptions = [
            ...Array(activity.people_max - activity.people_min + 1).keys()
        ].map(item => item + activity.people_min)

        $ctrl.form.values.activity_id = activity.id;
        $ctrl.form.values.people = activity.people_min;
    };

    $ctrl.$onInit = () => {
        scope = $ctrl.modal.scope;

        $ctrl.activities = scope.models.activities;
        $ctrl.errorKey = [$ctrl.errorKeyPreset, scope.index].join('.') + '.';

        $ctrl.form = FormBuilderService.build(scope.entity, (form) => {
            BookingService.calcPrice({
                gift_card_code: scope.gift_card_code,
                accommodations: scope.accommodations,
                custom_items: scope.custom_items,
                activities: scope.activities,
            }).then((res) => {
                $ctrl.modal.scope.submit({
                    data: res.data,
                    entity: scope.entity
                });
                $ctrl.close();
            }, res => {
                form.errors = res.data.errors;
                form.unlock();
            });
        }, true);

        if (!$ctrl.form.values.activity_id && $ctrl.activities[0]) {
            $ctrl.changeActivity($ctrl.activities[0].id || null);
        } else {
            $ctrl.changeActivity($ctrl.form.values.activity_id);
        }
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'FormBuilderService',
        'BookingService',
        ModalBookingActivityEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-booking-activity-edit.html';
    }
};