let OfferService = function(
    ApiRequest,
    DateService
) {
    let uriPrefix = '/offers';
    let offerableTypes = [
        ['accommodation', 'Accommodation'],
        ['activity', 'Activity'],
        ['fixed', 'Fixed'],
    ];

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            if (values.expires_at) {
                try {
                    values.expires_at = DateService._dateFormatYmd(
                        DateService._dateParse(values.expires_at)
                    );
                } catch (e) {}
            }

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            let values = JSON.parse(JSON.stringify(apiResource));

            values.discount_amount = parseFloat(values.discount_amount);
            values.old_price = parseFloat(values.old_price);
            values.price = parseFloat(values.price);

            if (values.expires_at) {
                try {
                    values.expires_at = DateService._dateFormat(
                        DateService._dateParseYmd(values.expires_at)
                    );
                } catch (e) {}
            }

            return values;
        };

        this.offerableTypes = () => {
            return offerableTypes.map(type => ({
                id: type[0],
                name: type[1]
            }));
        };
    });
};

module.exports = [
    'ApiRequest',
    'DateService',
    OfferService
];