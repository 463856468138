let ModalPromptComponent = function() {
    let $ctrl = this;

    $ctrl.class = [
        'modal-sm'
    ];

    $ctrl.$onInit = () => {
        $ctrl.prompt = $ctrl.modal.scope;

        $ctrl.accept = function() {
            $ctrl.modal.close();
            $ctrl.prompt.accept($ctrl);
        };

        $ctrl.decline = function() {
            $ctrl.modal.close();
            $ctrl.prompt.decline($ctrl);
        };
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        ModalPromptComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-prompt.html';
    }
};