let InterestService = function(ApiRequest) {
    let uriPrefix = '/interests';

    return new (function() {
        this.list = function(values) {
            return ApiRequest.get(uriPrefix, values);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            apiResource = JSON.parse(JSON.stringify(apiResource));
            apiResource.price = parseFloat(apiResource.price);

            return apiResource;
        };
    });
};

module.exports = [
    'ApiRequest',
    InterestService
];