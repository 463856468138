let AccommodationEditComponent = function(
    $q,
    LanguageService,
    AccommodationService,
    AccommodationTypeService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';

    // sortable settings for bots, options and controls
    $ctrl.sortable = {
        sort: true,
        animation: 150,
        handle: '.block-header-sortable',
        group: 'block-headers',
        me: function() {
            this.disabled = true;
        },
        ml: function() {
            this.disabled = false;
        }
    };

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.addOption = () => {
        $ctrl.form.values.options.push({
            translations: {},
            price: 0,
            daily: false,
            per_guest: false,
        });
    };

    $ctrl.deleteOption = (e, option) => {
        e.preventDefault() & e.stopPropagation();

        $ctrl.form.values.options.splice(
            $ctrl.form.values.options.indexOf(option), 1
        );
    };

    $ctrl.addDiscount = () => {
        $ctrl.form.values.discounts.push({
            days: 3,
            percentage: 5
        });
    };

    $ctrl.deleteDiscount = (e, discount) => {
        e.preventDefault() & e.stopPropagation();

        $ctrl.form.values.discounts.splice(
            $ctrl.form.values.discounts.indexOf(discount), 1
        );
    };

    $ctrl.loadAccommodation = (data) => {
        if (data.superseded_parent_id && $ctrl.accommodationId) {
            $ctrl.accommodations = $ctrl.accommodations.filter(item => {
                return item.id != data.superseded_parent_id
            });
        }

        data.options.forEach(option => {
            option.deletable = option.gift_card_options_count == 0 &&
                option.offer_options_count == 0
        });

        $ctrl.hasNonDeletableOptions = data.options.filter(option => !option.deletable).length > 0;

        $ctrl.media = data.photo;
        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.accommodationId) {
                $ctrl.updateAccommodation($ctrl.accommodationId, form);
            } else {
                $ctrl.storeAccommodation(form);
            }
        });
    };

    $ctrl.storeAccommodation = (form) => {
        AccommodationService.store(form.values).then((res) => {
            let url = '/admin/accommodations/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateAccommodation = (id, form) => {
        AccommodationService.update(id, form.values).then((res) => {
            PushNotificationsService.success('Saved');
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.loadLanguages = function(resolve, err) {
        LanguageService.list().then((res) => {
            resolve($ctrl.languages = res.data.data);
        }, err);
    };

    $ctrl.loadAccommodations = function(resolve, err) {
        AccommodationService.list().then((res) => {
            $ctrl.accommodations = res.data.data.filter(item => {
                return item.id != $ctrl.accommodationId;
            });
            $ctrl.accommodations.unshift({
                id: null,
                name: 'None'
            });
            resolve($ctrl.accommodations);
        }, err);
    };

    $ctrl.loadAccommodationTypes = function(resolve, err) {
        AccommodationTypeService.list().then((res) => {
            resolve($ctrl.accommodation_types = res.data.data);
        }, err);
    };

    $ctrl.$onInit = function() {
        $q.all([
            $q($ctrl.loadLanguages),
            $q($ctrl.loadAccommodations),
            $q($ctrl.loadAccommodationTypes),
        ]).then().then(() => {
            AccommodationTypeService.list().then((res) => {
                $ctrl.accommodation_types = res.data.data;

                if ($ctrl.accommodationId) {
                    return AccommodationService.read(
                        $ctrl.accommodationId
                    ).then(res => $ctrl.loadAccommodation(
                        AccommodationService.apiResourceToForm(res.data.data)
                    ), console.error);
                }

                $ctrl.loadAccommodation({
                    accommodation_type_id: $ctrl.accommodation_types[0].id,
                    btw: 9,
                    quantity: 1,
                    superseded_accommodation_id: null,
                    options: [],
                    discounts: [],
                });
            });
        });
    };
};

module.exports = {
    bindings: {
        'accommodationId': '<'
    },
    controller: [
        '$q',
        'LanguageService',
        'AccommodationService',
        'AccommodationTypeService',
        'PushNotificationsService',
        'FormBuilderService',
        AccommodationEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/accommodation-edit.html'
};