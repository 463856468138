let moment = require('moment');

let BookingService = function(
    ApiRequest,
    DateService
) {
    let uriPrefix = '/bookings';
    let states = [
        ['pending', 'Pending'],
        ['active', 'Active'],
        ['canceled', 'Canceled'],
        ['finished', 'Finished']
    ];

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.searchRange = function(query_params) {
            return ApiRequest.get(uriPrefix + '/search-range', query_params);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.calcPrice = function(data) {
            return ApiRequest.post(
                uriPrefix + '/calc-price', data
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            values.accommodations = (
                values.accommodations || []
            ).map(accommodation => {
                accommodation.options = accommodation.options || [];

                return accommodation;
            });

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            apiResource = JSON.parse(JSON.stringify(apiResource));
            apiResource.locale = apiResource.locale || 'nl';

            apiResource.accommodations.forEach(accommodation => {
                accommodation.start_date = DateService._dateFormat(
                    moment(accommodation.start_date)
                );

                accommodation.end_date = DateService._dateFormat(
                    moment(accommodation.end_date)
                );
            });

            apiResource.activities.forEach(activity => {
                activity.date = DateService._dateFormat(
                    moment(activity.date)
                );
            });


            return apiResource;
        };

        this.states = () => {
            return states.map(state => ({
                id: state[0],
                name: state[1]
            }));
        };
    });
};

module.exports = [
    'ApiRequest',
    'DateService',
    BookingService
];