let CheckInOffEditComponent = function(
    SettingService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.weekDays = {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday',
    };

    $ctrl.$onInit = function() {
        SettingService.getCheckInOffs().then(res => {
            $ctrl.form = FormBuilderService.build({
                week_days: res.data.data.map(row => {
                    return row.week_day.toString();
                })
            }, function(form) {
                SettingService.updateCheckInOffs(form.values).then(res => {
                    PushNotificationsService.success('Saved', 'check');
                });
            });
        });
    };
};

module.exports = {
    controller: [
        'SettingService',
        'PushNotificationsService',
        'FormBuilderService',
        CheckInOffEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/check-in-off-edit.html'
};