let CountryService = function(ApiRequest) {
    let uriPrefix = '/countries';

    return new (function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.show = function(id) {
            return ApiRequest.get(`${uriPrefix}/${id}`);
        };
    });
};

module.exports = [
    'ApiRequest',
    CountryService
];