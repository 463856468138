let SettingService = function(ApiRequest) {
    let uriPrefix = '/settings';

    return new (function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.getCheckInOffs = function(filters = {}) {
            return ApiRequest.get(
                uriPrefix + '/check-in-off',
                filters
            );
        }

        this.updateCheckInOffs = function(data) {
            return ApiRequest.patch(
                uriPrefix + '/check-in-off',
                data
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(formData) {
            let values = JSON.parse(JSON.stringify(formData));

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            return apiResource;
        };
    });
};

module.exports = [
    'ApiRequest',
    SettingService
];