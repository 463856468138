let InterestEditComponent = function(
    LanguageService,
    InterestService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';

    $ctrl.loadInterest = (data) => {
        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.interestId) {
                $ctrl.updateInterest($ctrl.interestId, form);
            } else {
                $ctrl.storeInterest(form);
            }
        });
    };

    $ctrl.storeInterest = (form) => {
        InterestService.store(form.values).then((res) => {
            let url = '/admin/interests/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateInterest = (id, form) => {
        InterestService.update(id, form.values).then((res) => {
            PushNotificationsService.success('Saved');
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.$onInit = function() {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.interestId) {
            return InterestService.read(
                $ctrl.interestId
            ).then(res => $ctrl.loadInterest(
                InterestService.apiResourceToForm(res.data.data)
            ), console.error);
        }

        $ctrl.loadInterest({
            btw: 9
        });
    };
};

module.exports = {
    bindings: {
        'interestId': '<'
    },
    controller: [
        'LanguageService',
        'InterestService',
        'PushNotificationsService',
        'FormBuilderService',
        InterestEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/interest-edit.html'
};