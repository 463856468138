let BookingListComponent = function(
    $q,
    $timeout,
    ArrService,
    BookingService
) {
    let $ctrl = this;
    let dateFormatYmd = 'YYYY-MM-DD';
    let dateFormatYmdHis = 'YYYY-MM-DD HH:mm:ss';

    $ctrl.selectedBookingType = 'booked';

    $ctrl.viewModes = ArrService.ObjToKeyValue({
        month: 'Month',
        isoWeek: 'Week',
        //day: 'Day',
    });

    $ctrl.selectType = (type) => {
        $ctrl.selectedBookingType = type;

        $ctrl.updateView();
    };

    $ctrl.filters = {

    };

    $ctrl.agendaData = [

    ];

    $ctrl.showBookingsCard = (e, item) => {
        e && e.stopPropagation();

        if ($ctrl.shownCard === item) {
            return $ctrl.showBookingsCard(e, null);
        }

        $ctrl.shownCard = item;
    };

    $ctrl.showMonth = (date, pushState = true) => {
        $ctrl.date = date.startOf($ctrl.viewMode);
        $ctrl.setViewMode('month', pushState);
        $ctrl.updateView();
    };

    $ctrl.showWeek = (date, pushState = true) => {
        $ctrl.date = date.startOf($ctrl.viewMode);
        $ctrl.setViewMode('isoWeek', pushState);
        $ctrl.updateView();
    };

    $ctrl.showDate = (date, pushState = true) => {
        $ctrl.date = date.startOf($ctrl.viewMode);
        $ctrl.setViewMode('day', pushState);
        $ctrl.updateView();
    };

    $ctrl.setViewMode = (viewMode, pushState) => {
        $ctrl.viewMode = viewMode;

        if (pushState) {
            $ctrl.setState('view', viewMode);
        }
    };

    $ctrl.getGroupsNumber = (groups) => {
        return groups.filter(group => group.bookings && group.bookings.length).length;
    };

    $ctrl.getGroupColor = (accommodation) => {
        return accommodation.snapshot_parent ?
            accommodation.snapshot_parent.accommodation_group.color :
            accommodation.accommodation_group.color;
    };

    $ctrl.composeAgendaData = (viewMode, bookings, startDate, endDate) => {
        let rangeStart = viewMode === 'day' ? startDate.clone() : startDate.clone().startOf('isoWeek');
        let rangeEnd = viewMode === 'day' ? endDate.clone() : endDate.clone().endOf('isoWeek');
        let date = rangeStart.clone();
        let dates = [];
        let now = moment();

        while (date.isBefore(rangeEnd)) {
            dates.push(date.clone());
            date.add(1, 'day');
        }

        if (viewMode === 'month') {
            return ArrService.arrayChunkSize(dates.map(date => {
                let bookings_filtered = bookings.filter(booking => {
                    return booking.date_moment.isSame(date, 'day');
                });

                let booking_groups = [];
                let booking_group_ids = [null];

                bookings_filtered.forEach((booking_accommodation) => {
                    let accommodations = booking_accommodation.accommodations;

                    accommodations.forEach(_accommodation => {
                        let group = _accommodation.accommodation.snapshot_parent ?
                            _accommodation.accommodation.snapshot_parent.accommodation_group :
                            _accommodation.accommodation.accommodation_group;
                        let _booking_accommodation = angular.copy(_accommodation);

                        _booking_accommodation.start_date = moment(
                            _booking_accommodation.start_date
                        ).format('DD-MM-YYYY');

                        _booking_accommodation.end_date = moment(
                            _booking_accommodation.end_date
                        ).format('DD-MM-YYYY');

                        if (!group) {
                            //booking_groups[0].bookings = [];
                            let dummyGroup = booking_groups.filter(booking_group => {
                                return booking_group.id === null;
                            })[0];

                            if (!dummyGroup) {
                                booking_groups.unshift({
                                    id: null,
                                    name: 'No group',
                                    color: '#000',
                                });
                            }

                            dummyGroup = booking_groups.filter(booking_group => {
                                return booking_group.id === null;
                            })[0];

                            if (!dummyGroup.bookings) {
                                dummyGroup.bookings = [];
                            }
                            dummyGroup.bookings.push(_booking_accommodation);
                        } else {
                            if (booking_group_ids.indexOf(group.id) === -1) {
                                booking_groups.push(group);
                            }

                            let insertedGroup = booking_groups.filter(booking_group => {
                                return booking_group.id === group.id;
                            })[0];

                            if (!insertedGroup.bookings) {
                                insertedGroup.bookings = [];
                            }
                            insertedGroup.bookings.push(_booking_accommodation);
                        }
                    });
                });
                // if (booking_groups.length) {
                //     console.log('booking_groups: ', booking_groups);
                // }

                return {
                    // isDisabled: !date.isBetween(startDate, endDate, null, '[]'),
                    isDisabled: false,
                    isToday: date.isSame(now, 'day'),
                    date: date,
                    week: date.format('w'),
                    day: date.format('DD'),
                    month: date.format('MM'),
                    year: date.format('YYYY'),
                    booking_groups: booking_groups.length ? booking_groups : [],
                };
            }), 7);
        } else if (viewMode === 'isoWeek') {
            let earlierHour = 8,
                latestHour = 16;

            if (bookings.length > 0) {
                earlierHour = latestHour = parseInt(
                    bookings[0].date_moment.format('H')
                );
            }

            bookings.forEach(booking => {
                let hour = parseInt(booking.date_moment.format('H'));

                earlierHour = Math.min(earlierHour, hour);
                latestHour = Math.max(latestHour, hour)
            });

            return {
                dates: dates.map(date => {
                    return {
                        date: date,
                        datePretty: date.format('dddd DD MMMM'),
                    };
                }),
                hours: ArrService.range(earlierHour, latestHour).map(function(hour) {
                    return {
                        hourPretty: (hour < 10 ? ('0' + hour) : hour) + ':00',
                        dates: dates.map(function(date) {
                            let _bookings = bookings.filter(function(
                                booking
                            ) {
                                let _hour = booking.date_moment.format('H');

                                return booking.date_moment.isSame(date, 'day') && (
                                    _hour >= hour && _hour < (hour + 1)
                                )
                            }).sort((a, b) => a.date_moment.diff(b.date_moment));

                            let _accommodations = [];
                            _bookings.forEach((booking_accommodation) => {
                                let accommodations = booking_accommodation.accommodations;

                                accommodations = accommodations.map(function(accommodation) {
                                    accommodation.start_date = moment(
                                        accommodation.start_date
                                    ).format('DD-MM-YYYY');

                                    accommodation.end_date = moment(
                                        accommodation.end_date
                                    ).format('DD-MM-YYYY');

                                    return accommodation;
                                });

                                _accommodations = Object.assign(accommodations, _accommodations);
                            });

                            return {
                                date: date,
                                bookings: _accommodations.length > 2 ? {
                                    first: _accommodations.slice(0, 1).map(
                                        _accommodation => [_accommodation]
                                    ),
                                    other: _accommodations.slice(1),
                                } : {
                                    first: _accommodations.slice(0, 2).map(
                                        _accommodation => [_accommodation]
                                    ),
                                    other: [],
                                },
                            };
                        })
                    };
                })
            };
        } else if (viewMode === 'day') {
            let earlierHour = 8,
                latestHour = 16;

            if (bookings.length > 0) {
                earlierHour = latestHour = bookings[0].date_moment.format('H');
            }

            bookings.forEach(booking => {
                let hour = parseInt(booking.date_moment.format('HH'));

                earlierHour = Math.min(earlierHour, hour);
                latestHour = Math.max(latestHour, hour)
            });

            let date = dates[0].clone();
            let hours = ArrService.range(earlierHour, latestHour).map(function(hour) {
                let _bookings = bookings.filter(function(
                    booking
                ) {
                    let _hour = booking.date_moment.format('H');

                    return booking.date_moment.isSame(date, 'day') && (
                        _hour >= hour && _hour < (hour + 1)
                    );
                }).sort((a, b) => a.date_moment.diff(b.date_moment));


                return {
                    hourPretty: (hour < 10 ? ('0' + hour) : hour) + ':00',
                    bookings: _bookings,
                };
            });

            return {
                date: date,
                datePretty: date.format('dddd DD, YYYY'),
                hours: hours,
                stats: {
                    bookings: hours.reduce(function(acc, hour) {
                        return acc + hour.bookings.length;
                    }, 0),
                    workingHours: (hours.reduce((acc, hour) => {
                        return acc + hour.bookings.reduce((
                            (_acc, booking) => booking.service.duration
                        ), 0);
                    }, 0) / 60).toFixed(2),
                    expectedRevenue: hours.reduce((acc, hour) => {
                        return acc + hour.bookings.reduce((
                            (_acc, booking) => _acc + parseFloat(booking.service.price)
                        ), 0);
                    }, 0),
                    // todo: formula
                    realRevenue: hours.reduce((acc, hour) => {
                        return acc + hour.bookings.reduce((
                            (_acc, booking) => _acc + parseFloat(booking.service.price)
                        ), 0);
                    }, 0)
                }
            };
        }

        return [];
    };

    $ctrl.parseState = (href = null) => {
        href = href ? href : document.location.href;

        let url = (href.indexOf('?') !== -1 ? href : href + '?').split('?');
        let uri = url[0];
        let params = url[1].split('&').filter(
            el => el.indexOf('=') !== -1
        ).map(
            el => el.split('=')
        ).reduce((coll, el) => {
            coll[el[0]] = el[1].indexOf(',') === -1 ? el[1] : el[1].split(',');
            return coll;
        }, {});

        return {
            uri: uri,
            params: params
        };
    };

    $ctrl.setState = (key, value = null) => {
        let state = $ctrl.parseState();
        let params = [];
        let url = state.uri;

        if ((!value && value != null) ||
            (Array.isArray(value)) && value.length === 0) {
            value = '';
        }

        state.params[key] = value;

        for (const key in state.params) {
            if (!state.params[key] && state.params[key] != null) {
                continue;
            }

            if (Array.isArray(state.params[key])) {
                params.push(key + '=' + state.params[key].join(','));
            } else {
                params.push(key + '=' + state.params[key]);
            }
        }
        params = params.join('&');

        url += params.length > 0 ? '?' + params : params;

        history.pushState({}, "", url);
    };

    $ctrl.updateView = (type, value = null) => {
        let startDate = $ctrl.date.clone().startOf(
            $ctrl.viewMode
        ).startOf('isoWeek');

        let endDate = $ctrl.date.clone().endOf(
            $ctrl.viewMode
        ).endOf('isoWeek');

        if (type && value != null) {
            $ctrl.setState(type, value);
        }

        BookingService.searchRange(Object.assign({
            start_date: startDate.format(dateFormatYmd),
            end_date: endDate.format(dateFormatYmd),
            type: $ctrl.selectedBookingType
        }, $ctrl.filters)).then(res => {
            $ctrl.agendaData = $ctrl.composeAgendaData(
                $ctrl.viewMode,
                res.data.map(function(booking_map) {
                    booking_map.date_time_pretty = booking_map.date;
                    booking_map.date_moment = moment(
                        booking_map.date,
                        dateFormatYmd
                    );

                    return booking_map;
                }),
                startDate,
                endDate,
            );
        });
    };

    $ctrl.nextPeriod = () => {
        $ctrl.date.add(1, $ctrl.viewMode);
        $ctrl.setState('date', $ctrl.date.format(dateFormatYmd));
        $ctrl.updateView();
    };

    $ctrl.prevPeriod = () => {
        $ctrl.date.subtract(1, $ctrl.viewMode);
        $ctrl.setState('date', $ctrl.date.format(dateFormatYmd));
        $ctrl.updateView();
    };

    $ctrl.$onInit = () => {
        $ctrl.viewMode = $ctrl.viewMode || $ctrl.viewModes[0].key;
        $ctrl.date = moment(
            $ctrl.targetDate || moment().format(dateFormatYmd),
            dateFormatYmd
        );

        $ctrl.filters = {
            states: BookingService.states,
        };

        $ctrl.updateView();

        window.onpopstate = history.onpopstate = function(e) {
            e.preventDefault();

            $timeout(() => {
                let state = $ctrl.parseState(e.target.location.href);
                let viewMode = state.params.view;
                let views = $ctrl.viewModes.map(view => view.key);
                let date = moment(state.params.date, dateFormatYmd);
                let arrayFilters = [
                    'state'
                ];

                arrayFilters.forEach(filterKey => {
                    let filter = state.params[filterKey] || [];

                    if (!Array.isArray(filter)) {
                        filter = [filter];
                    }

                    $ctrl.filters[filterKey] = filter.map(
                        id => parseInt(id)
                    );
                });

                if (!date._isValid) {
                    date = moment();
                }

                viewMode = views.indexOf(
                    viewMode
                ) === -1 ? 'month' : viewMode;

                if (viewMode === 'day') {
                    $ctrl.showDate(date, false);
                } else if (viewMode === 'isoWeek') {
                    $ctrl.showWeek(date, false);
                } else if (viewMode === 'month') {
                    $ctrl.showMonth(date, false);
                }
            });
        };
    };

    $ctrl.$onDestroy = () => {};
};

module.exports = {
    bindings: {
        'bookings': '<',
        'targetDate': '@date',
        'viewMode': '@',
    },
    controller: [
        '$q',
        '$timeout',
        'ArrService',
        'BookingService',
        BookingListComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/booking-list.html'
};