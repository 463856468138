let AccommodationService = function(ApiRequest) {
    let uriPrefix = '/accommodations';

    return new(function() {
        this.list = function(values) {
            return ApiRequest.get(uriPrefix, values);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            values.options.forEach((option) => {
                let originalOption = angular.copy(option);
                option.translations = option.translations || [];

                Object.assign(option, option.translations.nl || {}, option.id);
                option.id = originalOption.id;
            });

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            let data = JSON.parse(JSON.stringify(apiResource));
            let priceFields = [
                'price_min', 'price_extra_kid', 'price_extra_teen', 
                'price_extra_adult', 
            ];

            priceFields.forEach(priceField => {
                data[priceField] = parseFloat(data[priceField])
                data[priceField + '_high_season'] = parseFloat(
                    data[priceField + '_high_season'
                ])
            });

            data.options = (data.options || []).map(option => {
                option.price = parseFloat(option.price);
                return option;
            });

            data.discounts = (data.discounts || []).map(discount => {
                discount.percentage = parseFloat(discount.percentage);
                return discount;
            });

            return data;
        };
    });
};

module.exports = [
    'ApiRequest',
    AccommodationService
];