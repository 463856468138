let ModalDayOffEditComponent = function(
    DayOffService,
    FormBuilderService,
    AccommodationService
) {
    let $ctrl = this;
    let scope;

    $ctrl.scope = scope;
    $ctrl.dateFormat = 'yyyy-MM-dd'
    $ctrl.accommodations = [];

    $ctrl.$onInit = () => {
        scope = $ctrl.modal.scope;

        AccommodationService.list().then(res => {
            $ctrl.accommodations = res.data.data;

            $ctrl.accommodations.unshift({
                id: null,
                name: 'All'
            });

            $ctrl.form = FormBuilderService.build(
                scope.dayOff ? scope.dayOff : {
                    accommodation_id: null,
                    accommodation_quantity: 1,
                },
                form => {
                    (form.values.id ? DayOffService.update(
                        form.values.id,
                        form.values
                    ) : DayOffService.store(form.values)).then(res => {
                        scope.submit();
                        $ctrl.modal.close();
                    }, res => {
                        form.errors = res.data.errors;
                    })
                }
            );
        });
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'DayOffService',
        'FormBuilderService',
        'AccommodationService',
        ModalDayOffEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-day-off-edit.html';
    }
};