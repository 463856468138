module.exports = ['ModalRouteProvider', function(ModalRouteProvider) {
    ModalRouteProvider.modal('mediaEdit', {
        component: 'modalMediaEditComponent'
    });

    ModalRouteProvider.modal('bookingCustomItemEdit', {
        component: 'modalBookingCustomItemEditComponent'
    });

    ModalRouteProvider.modal('i18nEmailsEdit', {
        component: 'modalI18nEmailsEditComponent'
    });

    ModalRouteProvider.modal('dayOffEdit', {
        component: 'modalDayOffEditComponent'
    });

    ModalRouteProvider.modal('bookingActivityEdit', {
        component: 'modalBookingActivityEditComponent'
    });

    ModalRouteProvider.modal('bookingAccommodationEdit', {
        component: 'modalBookingAccommodationEditComponent'
    });

    ModalRouteProvider.modal('giftCardActivityEdit', {
        component: 'modalGiftCardActivityEditComponent'
    });

    ModalRouteProvider.modal('giftCardAccommodationEdit', {
        component: 'modalGiftCardAccommodationEditComponent'
    });

    ModalRouteProvider.modal('errorDetails', {
        component: 'modalErrorDetailsComponent'
    });

    ModalRouteProvider.modal('question', {
        component: 'modalQuestionComponent'
    });

    ModalRouteProvider.modal('prompt', {
        component: 'modalPromptComponent'
    });

    ModalRouteProvider.modal('giftcardCustomItemEdit', {
        component: 'modalGiftcardCustomItemEditComponent'
    });

    ModalRouteProvider.modal('markdownCustomLink', {
        component: 'modalMarkdownCustomLinkComponent'
    });
}];