let DaysOffEditComponent = function(
    ModalService,
    DayOffService
) {
    let $ctrl = this;

    $ctrl.items = null;

    $ctrl.editDate = (dayOff) => {
        ModalService.open('dayOffEdit', {
            dayOff: dayOff,
            submit: $ctrl.$onInit
        });
    };

    $ctrl.deleteDate = (dayOff) => {
        ModalService.open('prompt', {
            title: 'Confirmation',
            body: 'Do you confirm this action?',
            accept: () => {
                DayOffService.destroy(dayOff.id).then($ctrl.$onInit);
            },
            decline: () => {},
        });
    };

    $ctrl.addDate = () => {
        ModalService.open('dayOffEdit', {
            dayOff: null,
            submit: $ctrl.$onInit
        });
    };

    $ctrl.$onInit = function() {
        DayOffService.list({
            'group': 'mails'
        }).then(res => {
            $ctrl.items = typeof res.data.data == 'object' ?
                Object.values(res.data.data) : res.data.data;
        });
    };
};

module.exports = {
    bindings: {
        'i18nId': '<'
    },
    controller: [
        'ModalService',
        'DayOffService',
        DaysOffEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/days-off-edit.html'
};