let BaseController = function(
    $rootScope,
    $scope,
    $state,
    MediaService,
    appConfigs
) {
    $rootScope.$state = $state;
    $rootScope.appConfigs = appConfigs;
    $scope.appConfigs = appConfigs;

    MediaService.configs('dashboard').then((res) => {
        $rootScope.appConfigs.features = {
            medias: res.data
        };
    });
};

module.exports = [
    '$rootScope',
    '$scope',
    '$state',
    'MediaService',
    'appConfigs',
    BaseController
];