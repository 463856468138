module.exports = ['$translateProvider', function($translateProvider) {
    $translateProvider.translations('en', {
        
    });
    
    $translateProvider.translations('nl', {

    });

    $translateProvider.registerAvailableLanguageKeys(['en', 'nl']);

    $translateProvider.preferredLanguage('en');
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.useLocalStorage();
}];