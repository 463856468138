let ModalBookingAccommodationEditComponent = function(
    FormBuilderService,
    BookingService,
    DateService
) {
    let $ctrl = this;
    let scope;
    let accommodationsIds;

    $ctrl.dateFormat = 'dd-MM-yyyy'
    $ctrl.errorKeyPreset = 'accommodations';
    $ctrl.errorKey;

    $ctrl.onChangeStartDate = () => {
        let startDate = DateService._dateParse($ctrl.form.values.start_date);
        let endDate = DateService._dateParse($ctrl.form.values.end_date);

        if (startDate.isValid) {
            if (!endDate.isValid || !endDate.isAfter(startDate)) {
                $ctrl.form.values.end_date = DateService._dateFormat(
                    startDate.clone().add(1, 'days')
                );
            }
        }
    };

    $ctrl.indexOfOption = (option) => {
        return $ctrl.form.values.options.indexOf(option);
    };

    $ctrl.indexOfOptionById = (option) => {
        return $ctrl.form.values.options.indexOf(
            $ctrl.form.values.options.filter(
                _option => _option.accommodation_option_id == option
            )[0]
        );
    };

    $ctrl.toggleOption = (option) => {
        if ($ctrl.indexOfOptionById(option) == -1) {
            $ctrl.addOption(option);
        } else {
            $ctrl.removeOption(option);
        }
    };

    $ctrl.addOption = (id) => {
        $ctrl.form.values.options.push({
            accommodation_option_id: id,
            amount: 1,
        });
    };

    $ctrl.removeOption = (id) => {
        let options = $ctrl.form.values.options.filter(
            option => option.accommodation_option_id == id
        );

        options.forEach(option => {
            $ctrl.form.values.options.splice($ctrl.indexOfOption(option), 1);
        });
    };

    $ctrl.changeAccommodationType = (id) => {
        let values = $ctrl.form.values;

        $ctrl.accommodations = scope.models.accommodations.filter(
            accommodation => accommodation.accommodation_type_id == id
        );

        accommodationsIds = $ctrl.accommodations.map(item => item.id);

        if (accommodationsIds.indexOf(values.accommodation_id) == -1) {
            values.accommodation_id = $ctrl.accommodations[0].id || null;
        }

        $ctrl.changeAccommodation(values.accommodation_id);
    };

    $ctrl.changeAccommodation = (id) => {
        $ctrl.accommodation = $ctrl.accommodations.filter(item => {
            return item.id == id;
        })[0];

        $ctrl.options = $ctrl.accommodation ? $ctrl.accommodation.options : [];
    }

    $ctrl.$onInit = () => {
        scope = $ctrl.modal.scope;
        
        $ctrl.accommodation_types = scope.models.accommodation_types;
        $ctrl.accommodations = [];
        $ctrl.errorKey = [$ctrl.errorKeyPreset, scope.index].join('.') + '.';

        $ctrl.form = FormBuilderService.build(scope.entity, (form) => {
            let validOptions = $ctrl.accommodation.options.map(item => item.id);

            scope.entity.options = scope.entity.options.filter((option) => {
                return validOptions.indexOf(
                    option.accommodation_option_id) != -1;
            });
            
            BookingService.calcPrice({
                gift_card_code: scope.gift_card_code,
                accommodations: scope.accommodations,
                custom_items: scope.custom_items,
                activities: scope.activities,
            }).then(res => {
                $ctrl.modal.scope.submit({
                    data: res.data,
                    entity: scope.entity
                });
                $ctrl.close();
            }, res => {
                form.errors = res.data.errors;
                form.unlock();
            });
        }, true);

        $ctrl.changeAccommodationType(scope.entity.accommodation_type_id);
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'FormBuilderService',
        'BookingService',
        'DateService',
        ModalBookingAccommodationEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-booking-accommodation-edit.html';
    }
};