const app = angular.module('bookingApp', ['ui.router', 'pascalprecht.translate', 'ngCookies', 'uiCropper', 'ngLocale', 'ng-sortable', 'colorpicker']);
const appConfigs = {
    api_url: "/admin/api/"
};

app.constant('appConfigs', appConfigs);

// Controllers
app.controller('BaseController', require('./controllers/BaseController'));

// Components
app.component('i18nEditComponent', require('./components/Pages/I18nEditComponent'));
app.component('i18nEmailsEditComponent', require('./components/Pages/I18nEmailsEditComponent'));
app.component('offerEditComponent', require('./components/Pages/OfferEditComponent'));
app.component('customOfferEditComponent', require('./components/Pages/CustomOfferEditComponent'));
app.component('bookingEditComponent', require('./components/Pages/BookingEditComponent'));
app.component('activityEditComponent', require('./components/Pages/ActivityEditComponent'));
app.component('giftCardEditComponent', require('./components/Pages/GiftCardEditComponent'));
app.component('accommodationEditComponent', require('./components/Pages/AccommodationEditComponent'));
app.component('checkInOffEditComponent', require('./components/Pages/CheckInOffEditComponent'));
app.component('daysOffEditComponent', require('./components/Pages/DaysOffEditComponent'));
app.component('bookingListComponent', require('./components/Pages/BookingListComponent'));
app.component('accommodationGroupEditComponent', require('./components/Pages/AccommodationGroupEditComponent'));
app.component('bookingViewComponent', require('./components/Pages/BookingViewComponent'));
app.component('interestEditComponent', require('./components/Pages/InterestEditComponent'));

// Modal Components
app.component('modalPromptComponent', require('./components/Modals/ModalPromptComponent'));
app.component('modalQuestionComponent', require('./components/Modals/ModalQuestionComponent'));
app.component('modalMediaEditComponent', require('./components/Modals/ModalMediaEditComponent'));
app.component('modalDayOffEditComponent', require('./components/Modals/ModalDayOffEditComponent'));
app.component('modalErrorDetailsComponent', require('./components/Modals/ModalErrorDetailsComponent'));
app.component('modalI18nEmailsEditComponent', require('./components/Modals/ModalI18nEmailsEditComponent'));
app.component('modalBookingActivityEditComponent', require('./components/Modals/ModalBookingActivityEditComponent'));
app.component('modalGiftCardActivityEditComponent', require('./components/Modals/ModalGiftCardActivityEditComponent'));
app.component('modalBookingCustomItemEditComponent', require('./components/Modals/ModalBookingCustomItemEditComponent'));
app.component('modalBookingAccommodationEditComponent', require('./components/Modals/ModalBookingAccommodationEditComponent'));
app.component('modalGiftCardAccommodationEditComponent', require('./components/Modals/ModalGiftCardAccommodationEditComponent'));
app.component('modalMarkdownCustomLinkComponent', require('./components/Modals/ModalMarkdownCustomLinkComponent'));
app.component('modalGiftcardCustomItemEditComponent', require('./components/Modals/ModalGiftcardCustomItemEditComponent'));

// Services
app.service('DateService', require('./services/DateService'));
app.service('MediaService', require('./services/MediaService'));
app.service('ModalService', require('./services/ModalService'));
app.service('OfferService', require('./services/OfferService'));
app.service('CustomOfferService', require('./services/CustomOfferService'));
app.service('CountryService', require('./services/CountryService'));
app.service('GiftCardService', require('./services/GiftCardService'));
app.service('LanguageService', require('./services/LanguageService'));
app.service('TranslationService', require('./services/TranslationService'));
app.service('FormBuilderService', require('./services/FormBuilderService'));
app.service('ImageConvertorService', require('./services/ImageConvertorService'));
app.service('BookingInvoiceService', require('./services/BookingInvoiceService'));
app.service('ArrService', require('./services/ArrService'));
app.service('AccommodationGroupService', require('./services/AccommodationGroupService'));

app.service('DayOffService', require('./services/DayOffService'));
app.service('SettingService', require('./services/SettingService'));
app.service('BookingService', require('./services/BookingService'));
app.service('ValidateService', require('./services/ValidateService'));
app.service('ActivityService', require('./services/ActivityService'));
app.service('InterestService', require('./services/InterestService'));
app.service('AccommodationService', require('./services/AccommodationService'));
app.service('AccommodationTypeService', require('./services/AccommodationTypeService'));
app.service('PushNotificationsService', require('./services/PushNotificationsService'));

// Directives
app.directive('photoSelector', require('./directives/PhotoSelectorDirective'));
app.directive('preventPropagation', require('./directives/PreventPropagation'));
app.directive('markdown', require('./directives/MarkdownDirective'));

app.directive('openMediaModal', require('./directives/OpenMediaModal'));
app.directive('linkConfirmation', require('./directives/modal-triggers/LinkConfirmationDirective'));
app.directive('groupControlCheckbox', require('./directives/controls/GroupControlCheckboxDirective'));
app.directive('showErrorsDetailsModel', require('./directives/modal-triggers/ShowErrorsDetailsModelDirective'));

app.directive('modalsRoot', require('./directives/modals/ModalsRootDirective'));
app.directive('modalItem', require('./directives/modals/ModalItemDirective'));
app.directive('modalScrollBraker', require('./directives/modals/ModalScrollBrakerDirective'));

app.directive('agendaBookingsCard', require('./directives/AgendaBookingsCard'));
app.directive('clickOutside', require('./directives/ClickOutsideDirective'));

// Providers
app.provider('ApiRequest', require('./providers/ApiRequestProvider'));
app.provider('ModalRoute', require('./providers/ModalRouteProvider'));

// Filters
app.filter('currency_format', require('./filters/CurrencyFormatFilter'));
app.filter('to_upper_case', require('./filters/ToUpperCaseFilter'));
app.filter('pretty_json', require('./filters/PrettyJsonFilter'));
app.filter('file_size', require('./filters/FileSizeFilter'));
app.filter('to_fixed', require('./filters/ToFixedFilter'));
app.filter('split', require('./filters/splitFilter'));
app.filter('nl2br', require('./filters/nl2brFilter'));
app.filter('number_format', require('./filters/NumberFormatFilter'));

// Config
app.config(require('./routers/modals'));
app.config(require('./routers/router'));
app.config(require('./config/api-service'));
app.config(require('./config/i18n'));

app.config(['$compileProvider', function($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|javascript):/);
}]);

app.run(require('./routers/router-transitions'));

// Bootstrap the app
angular.bootstrap(document.querySelector('html'), ['bookingApp', '720kb.datepicker']);

module.exports = app;