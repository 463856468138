let CustomOfferEditComponent = function(
    $q,
    CustomOfferService,
    LanguageService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';
    $ctrl.dateFormat = 'dd-MM-yyyy'
    $ctrl.loaded = false;

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.loadOffer = (data) => {
        let translations = {};

        data.translations = Object.values(data.translations);

        data.translations.forEach(trans => translations[trans.locale] = trans);
        data.translations = translations;

        $ctrl.media = data.photo;
        $ctrl.form = FormBuilderService.build(data, (form) => {
            $ctrl.updateOffer($ctrl.customOfferId, form);
        });

        $ctrl.loaded = true;
    };

    $ctrl.updateOffer = (id, form) => {
        let values = JSON.parse(JSON.stringify(form.values));

        CustomOfferService.update(id, Object.assign(values)).then((res) => {
            PushNotificationsService.success('Saved');

            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');

            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.loadLanguages = function(resolve, err) {
        LanguageService.list().then((res) => {
            resolve($ctrl.languages = res.data.data);
        }, err);
    };

    $ctrl.$onInit = function() {
        $q.all([
            $q($ctrl.loadLanguages),
        ]).then().then(() => {
            return CustomOfferService.read(
                $ctrl.customOfferId
            ).then(res => $ctrl.loadOffer(
                CustomOfferService.apiResourceToForm(res.data.data)
            ), console.error);
        });
    };
};

module.exports = {
    bindings: {
        'customOfferId': '<',
    },
    controller: [
        '$q',
        'CustomOfferService',
        'LanguageService',
        'PushNotificationsService',
        'FormBuilderService',
        CustomOfferEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/custom-offer-edit.html'
};