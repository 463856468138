let ModalPromptComponent = function() {
    let $ctrl = this;

    $ctrl.$onInit = () => {
        $ctrl.title = $ctrl.modal.scope.title;
        $ctrl.error = $ctrl.modal.scope.error;
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        ModalPromptComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-error-details.html';
    }
};