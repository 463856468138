let AccommodationGroupEditComponent = function(
    $q,
    $timeout,
    LanguageService,
    AccommodationService,
    AccommodationTypeService,
    PushNotificationsService,
    FormBuilderService,
    AccommodationGroupService
) {
    let $ctrl = this;

    $ctrl.addAccommodation = (accommodationId) => {
        if (accommodationId == null) {
            return;
        }

        let selectedAccommodation = $ctrl.accommodations.filter(_accommodation => {
            return _accommodation.id === accommodationId;
        })[0];

        $ctrl.form.values.accommodations.push(selectedAccommodation);

        $ctrl.filterInvalidAccommodations();
    };

    $ctrl.removeAccommodation = (accommodation) => {
        let accommodations = $ctrl.form.values.accommodations;

        $ctrl.form.values.accommodations = accommodations.filter(_accommodation => {
            return _accommodation.id !== accommodation.id;
        });

        $ctrl.accommodations.push(accommodation);
    };

    $ctrl.storeAccommodationGroup = (form) => {
        AccommodationGroupService.store(form.values).then(res => {
            let url = '/admin/accommodation-group-settings/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateAccommodationGroup = (id, form) => {
        if ($ctrl.accommodationGroupId) {
            AccommodationGroupService.update(
                id, form.values
            ).then(res => {
                PushNotificationsService.success('Updated');
            });
        }
    };

    $ctrl.filterInvalidAccommodations = () => {
        $ctrl.formAccommodationIds = $ctrl.form.values.accommodations.map(_accommodation => {
            return _accommodation.id;
        });

        $ctrl.accommodations = $ctrl.accommodations.filter(_accommodation => {
            return $ctrl.formAccommodationIds.indexOf(_accommodation.id) === -1;
        });
    };

    $ctrl.buildAccommodationForm = (data = false) => {
        let _data = data ? data : {
            color: '#fff',
            accommodations: []
        };

        $ctrl.form = FormBuilderService.build(_data, form => {
            if ($ctrl.accommodationGroupId) {
                $ctrl.updateAccommodationGroup($ctrl.accommodationGroupId, form);
            } else {
                $ctrl.storeAccommodationGroup(form);
            }
        });
    };

    $ctrl.$onInit = function() {
        if (!$ctrl.accommodationGroupId) {
            $ctrl.buildAccommodationForm();
        }

        AccommodationService.list().then(res => {
            $ctrl.accommodations = res.data.data;
            $ctrl.accommodations.unshift({
                id: null,
                name: 'Select accommodation'
            });
            $ctrl.selectedAccommodation = $ctrl.accommodations[0].id;

            if ($ctrl.accommodationGroupId) {
                AccommodationGroupService.read($ctrl.accommodationGroupId).then(res => {
                    $ctrl.buildAccommodationForm(res.data.data);

                    $ctrl.filterInvalidAccommodations();
                });
            }
        });
    };
};

module.exports = {
    bindings: {
        'accommodationGroupId': '<'
    },
    controller: [
        '$q',
        '$timeout',
        'LanguageService',
        'AccommodationService',
        'AccommodationTypeService',
        'PushNotificationsService',
        'FormBuilderService',
        'AccommodationGroupService',
        AccommodationGroupEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/accommodation-group-edit.html'
};