let ActivityEditComponent = function(
    LanguageService,
    ActivityService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';
    $ctrl.btwOptions = [{
        value: 9,
        label: '9%'
    }, {
        value: 21,
        label: '21%'
    }];

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.loadActivity = (data) => {
        $ctrl.media = data.photo;
        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.activityId) {
                $ctrl.updateActivity($ctrl.activityId, form);
            } else {
                $ctrl.storeActivity(form);
            }
        });
    };

    $ctrl.storeActivity = (form) => {
        ActivityService.store(form.values).then((res) => {
            let url = '/admin/activities/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateActivity = (id, form) => {
        ActivityService.update(id, form.values).then((res) => {
            PushNotificationsService.success('Saved');
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.$onInit = function() {
        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;
        });

        if ($ctrl.activityId) {
            return ActivityService.read(
                $ctrl.activityId
            ).then(res => $ctrl.loadActivity(
                ActivityService.apiResourceToForm(res.data.data)
            ), console.error);
        }

        $ctrl.loadActivity({
            btw: 9
        });
    };
};

module.exports = {
    bindings: {
        'activityId': '<'
    },
    controller: [
        'LanguageService',
        'ActivityService',
        'PushNotificationsService',
        'FormBuilderService',
        ActivityEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/activity-edit.html'
};