let OfferEditComponent = function(
    $q,
    OfferService,
    LanguageService,
    ActivityService,
    AccommodationService,
    AccommodationTypeService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.languages;
    $ctrl.language = 'nl';
    $ctrl.dateFormat = 'dd-MM-yyyy'
    $ctrl.loaded = false;
    $ctrl.activitiesById = {};
    $ctrl.accommodationsById = {};

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.loadOffer = (data) => {
        let translations = {};

        data.translations.forEach(trans => translations[trans.locale] = trans);
        data.translations = translations;

        $ctrl.media = data.photo;
        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.offerId) {
                $ctrl.updateOffer($ctrl.offerId, form);
            } else {
                $ctrl.storeOffer(form);
            }
        });

        let accommodation_options = {};

        if (data.offerable_type == 'accommodation') {
            accommodation_options[data.offerable_id] = {};

            data.offer_accommodation_options.forEach(accommodation_option => {
                accommodation_options[data.offerable_id][accommodation_option] = true;
            });
        }
        
        $ctrl.form.accommodation_options = accommodation_options;
        $ctrl.loaded = true;
    };

    $ctrl.getSelectedOptions = (form) => {
        let accommodation_options = [];

        if (form.values.offerable_type == 'accommodation') {
            let options = form.accommodation_options[
                form.values.offerable_id
            ] || {};

            for (const key in options) {
                if (options[key]) {
                    accommodation_options.push(key);
                }
            }
        }

        return accommodation_options;
    };

    $ctrl.storeOffer = (form) => {
        let values = JSON.parse(JSON.stringify(form.values));

        console.log(values);

        OfferService.store(Object.assign(values, {
            accommodation_options: $ctrl.getSelectedOptions(form)
        })).then((res) => {
            let url = '/admin/offers/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateOffer = (id, form) => {
        let values = JSON.parse(JSON.stringify(form.values));

        OfferService.update(id, Object.assign(values, {
            accommodation_options: $ctrl.getSelectedOptions(form)
        })).then((res) => {
            let url = '/admin/offers/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            $ctrl.storeOffer = res.data.data.id;

            if (history) {
                history.pushState({
                    urlPath: url
                }, '', url);
            }
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.loadLanguages = function(resolve, err) {
        LanguageService.list().then((res) => {
            resolve($ctrl.languages = res.data.data);
        }, err);
    };

    $ctrl.loadAccommodations = function(resolve, err) {
        AccommodationService.list().then((res) => {
            $ctrl.accommodations = res.data.data;
            $ctrl.accommodations.forEach(accommodation => {
                $ctrl.accommodationsById[accommodation.id] = accommodation;
            });

            resolve($ctrl.accommodations);
        }, err);
    };

    $ctrl.loadActivities = function(resolve, err) {
        ActivityService.list().then((res) => {
            $ctrl.activities = res.data.data;
            $ctrl.activities.forEach(activity => {
                $ctrl.activitiesById[activity.id] = activity;
            });
            
            resolve($ctrl.accommodations);
        }, err);
    };

    $ctrl.loadAccommodationTypes = function(resolve, err) {
        AccommodationTypeService.list().then((res) => {
            $ctrl.accommodation_types = res.data.data;
            $ctrl.accommodation_types_by_id = {};

            $ctrl.accommodation_types.forEach(type => {
                $ctrl.accommodation_types_by_id[type.id] = type.name;
            });
            
            resolve($ctrl.accommodation_types);
        }, err);
    };

    $ctrl.$onInit = function() {
        $ctrl.offerableTypes = OfferService.offerableTypes();

        $q.all([
            $q($ctrl.loadLanguages),
            $q($ctrl.loadActivities),
            $q($ctrl.loadAccommodations),
            $q($ctrl.loadAccommodationTypes),
        ]).then().then(() => {
            if ($ctrl.offerId) {
                return OfferService.read(
                    $ctrl.offerId
                ).then(res => $ctrl.loadOffer(
                    OfferService.apiResourceToForm(res.data.data)
                ), console.error);
            }

            let offerable_type = $ctrl.offerableTypes[0].id;
            let offerable = offerable_type == 'activity' ?
                $ctrl.activities : $ctrl.accommodations;

            $ctrl.loadOffer({
                code: $ctrl.code,
                quantity: 1,
                nights: 1,
                people: 1,
                btw: 9,
                offer_accommodation_options: [],
                offerable_type: offerable_type,
                offerable_id: offerable[0].id,
                translations: [],
            });
        });
    };
};

module.exports = {
    bindings: {
        'offerId': '<',
    },
    controller: [
        '$q',
        'OfferService',
        'LanguageService',
        'ActivityService',
        'AccommodationService',
        'AccommodationTypeService',
        'PushNotificationsService',
        'FormBuilderService',
        OfferEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/offer-edit.html'
};