let ModalI18nEmailsEditComponent = function(
    $q,
    LanguageService,
    FormBuilderService,
    TranslationService
) {
    let $ctrl = this;
    let scope;

    $ctrl.languages;
    $ctrl.language = 'nl';

    $ctrl.translations = TranslationService.appTranslations('mails');

    $ctrl.submit = () => {
        $q.all($ctrl.fields.map(field => {
            return TranslationService.update(field.id, field);
        })).then(res => {
            console.log('Saved');
            $ctrl.modal.close();
        });
    };

    $ctrl.$onInit = () => {
        
        scope = $ctrl.modal.scope;

        $ctrl.transTitle = $ctrl.translations[scope.groupKey].title;
        $ctrl.transFields = $ctrl.translations[scope.groupKey].fields;

        LanguageService.list().then((res) => {
            $ctrl.languages = res.data.data;

            TranslationService.list({
                'group': 'mails',
                'namespace': scope.groupKey,
            }).then(res => {
                $ctrl.fields = res.data.data.map(item => {
                    item.input_type = [
                        'subject', 'title', 'button_invoice', 'activity_item', 
                        'accommodation_item'
                    ].indexOf(item.item) == -1 ? 'textarea' : 'text'
                    
                    return item;
                });
            });
        });
    };

    $ctrl.$onDestroy = function() {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        '$q',
        'LanguageService',
        'FormBuilderService',
        'TranslationService',
        ModalI18nEmailsEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-i18n-emails-edit.html';
    }
};