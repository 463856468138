let BookingInvoiceService = function(
    ApiRequest
) {
    let uriPrefix = '/bookings/';

    return new(function() {
        this.sendToClient = function(booking_id, booking_invoice_id) {
            return ApiRequest.post(
                uriPrefix + booking_id + '/invoices/' + booking_invoice_id + '/send-to-client'
            );
        };

        this.setPaid = function(booking_id, booking_invoice_id, notify = true) {
            return ApiRequest.post(
                uriPrefix + booking_id + '/invoices/' + booking_invoice_id + '/set-paid', {
                    notify: notify
                }
            );
        };
    });
};

module.exports = [
    'ApiRequest',
    BookingInvoiceService
];