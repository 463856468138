let GroupControlCheckboxDirective = function($scope) {
    $scope.$watch('value', function(n, o, s) {
        if ($scope.value === undefined ||
            $scope.options === undefined)
            return;

        if ($scope.change !== undefined)
            $scope.change();
    });

    $scope.selectOption = function(_k) {
        if ($scope.value.indexOf(_k) != -1)
            $scope.value.splice($scope.value.indexOf(_k), 1);
        else
            $scope.value.push(_k);
    };

    $scope.selectClass = function(_k) {
        return [
            ($scope.value || []).indexOf(_k) != -1 ? 'checked' : ''
        ];
    };
};
module.exports = () => {
    return {
        require: [
            'ngModel'
        ],
        scope: {
            change: '=',
            options: '=',
            value: '=ngModel',

        },
        controller: [
            '$scope',
            GroupControlCheckboxDirective
        ],
        templateUrl: function(elem, attr) {
            if (!attr.template) {
                attr.template = 'checkbox';
            }

            return '/assets/panel/tpl/directives/controls/group-control-' + attr.template + '.html';
        }
    }
};