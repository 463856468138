let TranslationService = function(
    ApiRequest
) {
    let uriPrefix = '/i18n';

    return new(function() {
        this.list = function(data = {}) {
            return ApiRequest.get(uriPrefix, data);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            apiResource = JSON.parse(JSON.stringify(apiResource));

            return apiResource;
        };

        this.appTranslations = function(key = null) {
            let translations = {
                mails: require('../i18n/mails'),
            };

            return key ? translations[key] : translations;
        };
    });
};

module.exports = [
    'ApiRequest',
    TranslationService
];