let GiftCardEditComponent = function(
    $q,
    ModalService,
    GiftCardService,
    ActivityService,
    AccommodationService,
    PushNotificationsService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.dateFormat = 'dd-MM-yyyy'
    $ctrl.giftCard = null;
    $ctrl.isUsed = false;
    $ctrl.loaded = false;

    $ctrl.removeActivity = (activity) => {
        let index = $ctrl.form.values.activities.indexOf(activity);

        if (index != -1) {
            $ctrl.form.values.activities.splice(index, 1);
        }
    };

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.deletePhoto = () => {
        delete $ctrl.media;
        $ctrl.form.values.media_id = null;
    };

    $ctrl.removeAccommodation = (accommodation) => {
        let index = $ctrl.form.values.accommodations.indexOf(accommodation);

        if (index != -1) {
            $ctrl.form.values.accommodations.splice(index, 1);
        }
    };

    $ctrl.editAccommodation = (accommodation) => {
        ModalService.open('giftCardAccommodationEdit', {
            accommodation: accommodation ? accommodation : {
                type: 'percentage',
                accommodation_id: $ctrl.accommodations[0] ? $ctrl.accommodations[0].id : null,
                nights: 1,
                guests: 1,
                accommodation_options: []
            },
            isUsed: $ctrl.isUsed,
            types: $ctrl.types,
            accommodations: $ctrl.accommodations,
            submit: (_accommodation) => {
                let index = $ctrl.form.values.accommodations.indexOf(accommodation);

                if (index == -1) {
                    $ctrl.form.values.accommodations.push(_accommodation);
                } else {
                    $ctrl.form.values.accommodations[index] = _accommodation;
                }
            }
        });
    };

    $ctrl.editActivity = (activity) => {
        ModalService.open('giftCardActivityEdit', {
            activity: activity ? activity : {
                'type': 'percentage',
                'activity_id': $ctrl.activities[0] ? $ctrl.activities[0].id : null,
                'guests': 1,
            },
            isUsed: $ctrl.isUsed,
            types: $ctrl.types,
            activities: $ctrl.activities,
            submit: (_activity) => {
                let index = $ctrl.form.values.activities.indexOf(activity);

                if (index == -1) {
                    $ctrl.form.values.activities.push(_activity);
                } else {
                    $ctrl.form.values.activities[index] = _activity;
                }
            }
        });
    };

    $ctrl.editCustomItem = (customItem) => {
        let custom_items = $ctrl.form.values.custom_items;

        if (customItem) {
            customItem.price = customItem.price ? parseFloat(customItem.price) : 0;
            customItem.btw = customItem.btw ? parseFloat(customItem.btw) : 0;
        }

        ModalService.open('giftcardCustomItemEdit', {
            customItem: customItem,
            submit: (res) => {
                console.log(res, res.custom_item);
                if (!customItem) {
                    custom_items.push(res);
                } else {
                    customItem = res;
                }
            }
        });
    }

    $ctrl.removeCustomItem = (customItem) => {
        $ctrl.form.values.custom_items = $ctrl.form.values.custom_items.filter(item => {
            return item.id != customItem.id;
        });
    }

    $ctrl.loadGiftCard = (data) => {
        $ctrl.media = data.photo;
        
        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.giftCardId) {
                $ctrl.updateGiftCard($ctrl.giftCardId, form);
            } else {
                $ctrl.storeGiftCard(form);
            }
        });
    };

    $ctrl.storeGiftCard = (form) => {
        GiftCardService.store(form.values).then((res) => {
            let url = '/admin/gift-cards/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.updateGiftCard = (id, form) => {
        GiftCardService.update(id, form.values).then((res) => {
            let url = '/admin/gift-cards/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            $ctrl.storeGiftCard = res.data.data.id;

            if (history) {
                history.pushState({
                    urlPath: url
                }, '', url);
            }
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.loadAccommodations = () => {
        return $q((resolve, reject) => {
            AccommodationService.list().then((res) => {
                resolve($ctrl.accommodations = res.data.data);
            }, reject)
        });
    };

    $ctrl.loadActivities = () => {
        return $q((resolve, reject) => {
            ActivityService.list().then((res) => {
                resolve($ctrl.activities = res.data.data);
            }, reject)
        });
    };

    $ctrl.$onInit = function() {
        $ctrl.types = GiftCardService.types();
        $ctrl.giftCard = false;

        $q.all([
            $ctrl.loadActivities(),
            $ctrl.loadAccommodations(),
        ]).then(() => {
            $ctrl.loaded = true;

            if ($ctrl.giftCardId) {
                return GiftCardService.read(
                    $ctrl.giftCardId
                ).then(res => {
                    $ctrl.giftCard = res.data.data;
                    $ctrl.isUsed = $ctrl.giftCard.bookings_count > 0;
                    $ctrl.loadGiftCard(GiftCardService.apiResourceToForm(res.data.data));
                }, console.error);
            }

            $ctrl.loadGiftCard({
                code: $ctrl.code,
                type: 'percentage',
                quantity: 1,
                accommodations_type: 'percentage',
                activities_type: 'percentage',
                accommodations_amount: 0,
                activities_amount: 0,
                accommodations: [],
                activities: [],
                custom_items: [],
            });
        });
    };
};

module.exports = {
    bindings: {
        'giftCardId': '<',
        'code': '@'
    },
    controller: [
        '$q',
        'ModalService',
        'GiftCardService',
        'ActivityService',
        'AccommodationService',
        'PushNotificationsService',
        'FormBuilderService',
        GiftCardEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/gift-card-edit.html'
};