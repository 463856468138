let GiftCardService = function(
    ApiRequest,
    DateService
) {
    let uriPrefix = '/gift-cards';
    let types = [
        ['percentage', 'Percentage'],
        ['fixed', 'Fixed']
    ];

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.store = function(values) {
            return ApiRequest.post(
                uriPrefix,
                this.apiFormToResource(values)
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        }

        this.destroy = function(id) {
            return ApiRequest.delete(
                uriPrefix + '/' + id
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            if (data.expire_at) {
                try {
                    data.expire_at = DateService._dateFormat(data.expire_at);
                } catch (e) {}
            }

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            apiResource = JSON.parse(JSON.stringify(apiResource));

            /* apiResource.expires_at = DateService._dateFormat(moment(
                apiResource.expires_at
            )); */

            return apiResource;
        };

        this.types = () => {
            return types.map(type => ({
                id: type[0],
                name: type[1]
            }));
        };
    });
};

module.exports = [
    'ApiRequest',
    'DateService',
    GiftCardService
];