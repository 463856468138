let ArrService = function() {
    this.ObjToKeyValue = (obj) => {
        return Object.keys(obj).filter(function(key) {
            return obj.hasOwnProperty(key);
        }).map(key => ({
            key: key,
            value: obj[key],
        }))
    };
    this.ArrayPluck = (obj, key, value) => {
        let _out = {};

        obj.forEach(el => {
            _out[el[key]] = el[value];
        });

        return _out;
    };

    this.arrayChunkSize = (arr, chunkSize) => {
        let _arr = arr.slice();
        let _out = [];

        while (_arr.length > 0) {
            _out.push(_arr.splice(0, chunkSize));
        }

        return _out;
    };

    this.range = (start, end) => {
        return Array((end - start) + 1).fill().map(() => start++);
    };

    this.arrayChunkCount = (arr, chunksCount) => {
        return this.arrayChunkSize(arr, Math.ceil(arr.length / chunksCount));
    };
};

module.exports = [function() {
    return new ArrService();
}];