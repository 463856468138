let BookingEditComponent = function(
    $q,
    ModalService,
    DateService,
    BookingService,
    CountryService,
    ActivityService,
    BookingInvoiceService,
    AccommodationService,
    AccommodationTypeService,
    PushNotificationsService,
    LanguageService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.selectPhoto = (media) => {
        $ctrl.media = media;
        $ctrl.form.values.media_id = $ctrl.media.id;
    };

    $ctrl.addActivity = () => $ctrl.editEntity('activities');
    $ctrl.editActivity = item => $ctrl.editEntity('activities', item);
    $ctrl.removeActivity = item => $ctrl.removeEntity('activities', item);

    $ctrl.addCustomItem = () => $ctrl.editEntity('custom_items');
    $ctrl.editCustomItem = item => $ctrl.editEntity('custom_items', item);
    $ctrl.removeCustomItem = item => $ctrl.removeEntity('custom_items', item);

    $ctrl.addAccommodation = () => $ctrl.editEntity('accommodations');
    $ctrl.editAccommodation = item => $ctrl.editEntity('accommodations', item);
    $ctrl.removeAccommodation = item => $ctrl.removeEntity('accommodations', item);

    $ctrl.clone = target => JSON.parse(JSON.stringify(target));

    $ctrl.languages;
    $ctrl.language = 'nl';

    $ctrl.editEntity = (type, entity = false, recalculate = false) => {
        let modalKey = {
            activities: 'bookingActivityEdit',
            custom_items: 'bookingCustomItemEdit',
            accommodations: 'bookingAccommodationEdit',
        } [type];

        let index;
        let isNew = false;
        let accommodations = $ctrl.form.values.accommodations.map($ctrl.clone);
        let custom_items = $ctrl.form.values.custom_items.map($ctrl.clone);
        let activities = $ctrl.form.values.activities.map($ctrl.clone);

        if (type == 'accommodations') {
            if (entity == false) {
                accommodations.push({
                    adults: 1,
                    teens: 0,
                    kids: 0,
                    options: []
                });

                index = accommodations.length - 1;
                isNew = true;
            } else {
                index = $ctrl.form.values.accommodations.indexOf(entity);
            }

            entity = accommodations[index];

            if (entity.accommodation) {
                entity.accommodation_type_id =
                    entity.accommodation.accommodation_type_id;
            } else {
                entity.accommodation_type_id =
                    $ctrl.accommodation_types[0].id || null
            }

            accommodations.forEach(accommodation => {
                accommodation.accommodations = accommodations.filter(
                    _accommodation => (
                        _accommodation != accommodation
                    ) && (isNew ? _accommodation != entity : true)
                ).map(_accommodation => ({
                    accommodation_id: _accommodation.accommodation_id,
                    start_date: _accommodation.start_date,
                    end_date: _accommodation.end_date,
                    adults: _accommodation.adults,
                    teens: _accommodation.teens,
                    kids: _accommodation.kids,
                    id: _accommodation.id,
                }));
            });
        } else if (type == 'custom_items') {
            if (entity == false) {
                custom_items.push({
                    quantity: 1
                });

                index = custom_items.length - 1;
                isNew = true;
            } else {
                index = $ctrl.form.values.custom_items.indexOf(entity);
            }

            entity = custom_items[index];

            custom_items.forEach(custom_item => {
                custom_item.price ? parseFloat(custom_item.price) : 0;
                custom_item.btw ? parseFloat(custom_item.btw) : 0;
            });
        } else if (type == 'activities') {

            if (entity == false) {
                activities.push({
                    adults: 1,
                    teens: 0,
                    kids: 0,
                });

                index = activities.length - 1;
                isNew = true;
            } else {
                index = $ctrl.form.values.activities.indexOf(entity);
            }

            entity = activities[index];

            activities.forEach(activity => {
                activity.activities = activities.filter(
                    _activity => (
                        _activity != activity
                    ) && (isNew ? _activity != entity : true)
                ).map(_activity => ({
                    activity_id: _activity.activity_id,
                    people: _activity.people,
                    date: _activity.date,
                    id: _activity.id,
                }));
            });
        }

        ModalService.open(modalKey, {
            index: index,
            entity: entity,
            booking_id: $ctrl.bookingId,
            gift_card_code: $ctrl.form.values.gift_card_code || '',

            // bookable items
            activities: activities,
            custom_items: custom_items,
            accommodations: accommodations,

            // models
            models: {
                activities: $ctrl.activities,
                accommodations: $ctrl.accommodations,
                accommodation_types: $ctrl.accommodation_types,
            },
            submit: (res) => {
                $ctrl.applyBookingPrices(res, index, isNew, type);
            }
        });
    };

    $ctrl.applyBookingPrices = (res, index, isNew, type = false) => {
        if (res.entity) {
            if (isNew) {
                $ctrl.form.values[type].push(res.entity);
            } else {
                $ctrl.form.values[type][index] = res.entity;
            }
        }

        if (type != 'custom_items' && res.data != null) {
            $ctrl.form.values.accommodations.forEach((entity, _index) => {
                entity.price = res.data.accommodations[_index].price_inclusive;
                entity.accommodation = $ctrl.accommodations.filter(
                    accommodation => accommodation.id == entity.accommodation_id
                )[0];
            });

            $ctrl.form.values.activities.forEach((entity, _index) => {
                entity.price = res.data.activities[_index].price_inclusive;
                entity.activity = $ctrl.activities.filter(
                    activity => activity.id == entity.activity_id
                )[0];
            });
        }

        $ctrl.formatBookingDates();
    };

    $ctrl.removeEntity = (type, item) => {
        let index = $ctrl.form.values[type].indexOf(item);

        if (index != -1) {
            $ctrl.form.values[type].splice(index, 1);
        }

        if (type == 'custom_items') {
            return;
        }

        let accommodations = $ctrl.form.values.accommodations.map($ctrl.clone);
        let custom_items = $ctrl.form.values.custom_items.map($ctrl.clone);
        let activities = $ctrl.form.values.activities.map($ctrl.clone);

        BookingService.calcPrice({
            gift_card_code: $ctrl.form.values.gift_card_code || '',
            accommodations: accommodations,
            custom_items: custom_items,
            activities: activities,
        }).then(res => {
            $ctrl.applyBookingPrices({
                data: res.data,
                entity: null
            }, false, false);
        }, console.error);
    };

    $ctrl.sendInvoice = (e, booking_invoice) => {
        e && (e.prevendDefault() & e.stopPropagation());

        if (booking_invoice.sending || (
                booking_invoice.state == 'pending' && !confirm(
                    'Sending invoice to client will make this invoice ' +
                    'non editable, do you want to proceed?'
                ))) {
            return;
        }

        booking_invoice.sending = true;

        BookingInvoiceService.sendToClient(
            booking_invoice.booking_id,
            booking_invoice.id
        ).then(res => {
            booking_invoice.sending = false;
            booking_invoice = res.data.data;
            ModalService.open('question', {
                title: 'Success.',
                body: "Invoice has been sent to the client.",
                autoClose: true,
                buttons: [{
                    name: "Ok",
                    class: ['btn', 'btn-primary']
                }]
            });
        }, () => {
            booking_invoice.sending = false;
            ModalService.open('question', {
                title: 'Error.',
                body: "Something went wrong while sending invoice to client.",
                autoClose: true,
                buttons: [{
                    name: "Ok",
                    class: ['btn', 'btn-primary']
                }]
            });
        });
    };

    $ctrl.setInvoicePaid = (e, booking_invoice) => {
        e && (e.prevendDefault() & e.stopPropagation());

        let setPaid = (sendEmail = true) => {
            BookingInvoiceService.setPaid(
                booking_invoice.booking_id,
                booking_invoice.id,
                sendEmail
            ).then(res => {
                booking_invoice = Object.assign(
                    booking_invoice,
                    res.data.data
                );

                ModalService.open('question', {
                    title: 'Success.',
                    body: "Invoice successfully paid.",
                    autoClose: true,
                    buttons: [{
                        name: "Ok",
                        class: ['btn', 'btn-primary']
                    }]
                });
            }, () => {
                ModalService.open('question', {
                    title: 'Error.',
                    body: "Something went wrong while making invoice paid.",
                    autoClose: true,
                    buttons: [{
                        name: "Ok",
                        class: ['btn', 'btn-primary']
                    }]
                });
            });
        };

        if (!booking_invoice.reservation_invoice) {
            return setPaid();
        }

        ModalService.open('question', {
            title: 'Email notification',
            body: [
                "This is the reservation invoice, this means that booking will be confirmed.",
                "do you want to send \"booking confirmed\" e-mail notification to the client?"
            ].join("\n"),
            autoClose: true,
            buttons: [{
                name: "Yes",
                class: ['btn', 'btn-danger'],
                key: 'yes'
            }, {
                name: "No",
                class: ['btn', 'btn-dark'],
                key: 'no'
            }],
            submit: (form, button) => {
                if (form && button) {
                    setPaid(button.key == 'yes');
                }
            }
        });
    };

    $ctrl.formatBookingDates = () => {
        $ctrl.form.values.accommodations.map(accommodation => {
            accommodation.start_date_format = DateService._datePrettyFormat(
                DateService._dateParse(accommodation.start_date)
            );

            accommodation.end_date_format = DateService._datePrettyFormat(
                DateService._dateParse(accommodation.end_date)
            );

            return accommodation;
        });

        $ctrl.form.values.invoices.map(invoice => {

            return invoice;
        });

        $ctrl.countPendingInvoices = $ctrl.form.values.invoices.filter(
            invoice => invoice.state == 'pending'
        );

        $ctrl.form.values.activities.map(activity => {
            activity.date_format = DateService._datePrettyFormat(
                DateService._dateParse(activity.date)
            );

            return activity;
        });
    };

    $ctrl.initBooking = (data) => {
        $ctrl.media = data.photo;
        if (!data.locale) {
            data.locale = $ctrl.language;
        }

        $ctrl.form = FormBuilderService.build(data, (form) => {
            if ($ctrl.bookingId) {
                $ctrl.updateBooking($ctrl.bookingId, form);
            } else {
                $ctrl.storeBooking(form);
            }
        });

        $ctrl.formatBookingDates();
    };

    $ctrl.storeBooking = (form) => {
        BookingService.store(form.values).then((res) => {
            let url = '/admin/bookings/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            document.location.href = url;

        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
            setTimeout(() => {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.form-error').closest('.form-group').offset().top
                }, 1500);
            }, 100);
        });
    };

    $ctrl.updateBooking = (id, form) => {
        BookingService.update(id, form.values).then((res) => {
            let url = '/admin/bookings/' + res.data.data.id + '/edit';

            PushNotificationsService.success('Saved');
            $ctrl.bookingId = res.data.data.id;

            if (history) {
                history.pushState({
                    urlPath: url
                }, '', url);
            }
            $ctrl.$onInit();
        }, (res) => {
            PushNotificationsService.danger('Some fields seems to be invalid.');
            $ctrl.form.errors = res.data.errors;
        });
    };

    $ctrl.loadCountries = function(resolve, err) {
        CountryService.list().then((res) => {
            resolve($ctrl.countries = res.data.data);
        }, err);
    };

    $ctrl.loadAccommodationTypes = function(resolve, err) {
        AccommodationTypeService.list().then((res) => {
            resolve($ctrl.accommodation_types = res.data.data);
        }, err);
    };

    $ctrl.loadAccommodations = function(resolve, err) {
        AccommodationService.list({
            'booking_id': $ctrl.bookingId
        }).then((res) => {
            resolve($ctrl.accommodations = res.data.data);
        }, err);
    };

    $ctrl.loadActivities = function(resolve, err) {
        ActivityService.list({
            'booking_id': $ctrl.bookingId
        }).then((res) => {
            resolve($ctrl.activities = res.data.data);
        }, err);
    };


    $ctrl.loadBooking = function(resolve, err) {
        if ($ctrl.bookingId) {
            return BookingService.read(
                $ctrl.bookingId
            ).then(res => {
                resolve(res) & $ctrl.initBooking(
                    BookingService.apiResourceToForm(res.data.data)
                );
            }, err);
        }

        $ctrl.initBooking({
            send_notification: true,
            state: 'pending',
            country_id: 163,
            is_paid: false,
            accommodations: [],
            custom_items: [],
            activities: [],
            invoices: [],
        });
    };

    $ctrl.loadLanguages = function(resolve, err) {
        LanguageService.list().then((res) => {
            resolve($ctrl.languages = res.data.data);
        }, err);
    };

    $ctrl.$onInit = function() {
        $ctrl.booking_states = BookingService.states();

        $q.all([
            $q($ctrl.loadCountries),
            $q($ctrl.loadActivities),
            $q($ctrl.loadAccommodations),
            $q($ctrl.loadAccommodationTypes),
            $q($ctrl.loadBooking),
            $q($ctrl.loadLanguages),
        ]).then().then(() => {});
    };
};

module.exports = {
    bindings: {
        'bookingId': '<'
    },
    controller: [
        '$q',
        'ModalService',
        'DateService',
        'BookingService',
        'CountryService',
        'ActivityService',
        'BookingInvoiceService',
        'AccommodationService',
        'AccommodationTypeService',
        'PushNotificationsService',
        'LanguageService',
        'FormBuilderService',
        BookingEditComponent
    ],
    templateUrl: '/assets/panel/tpl/pages/booking-edit.html'
};