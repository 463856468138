let CustomOfferService = function(
    ApiRequest,
) {
    let uriPrefix = '/custom-offers';

    return new(function() {
        this.list = function() {
            return ApiRequest.get(uriPrefix);
        };

        this.read = function(id) {
            return ApiRequest.get(
                uriPrefix + '/' + id
            );
        };

        this.update = function(id, values) {
            return ApiRequest.patch(
                uriPrefix + '/' + id,
                this.apiFormToResource(values)
            );
        };

        this.apiFormToResource = function(data) {
            let values = JSON.parse(JSON.stringify(data));

            values.translations = values.translations || [];
            Object.assign(values, values.translations.nl || {});

            return values;
        };

        this.apiResourceToForm = function(apiResource) {
            let values = JSON.parse(JSON.stringify(apiResource));

            return values;
        };
    });
};

module.exports = [
    'ApiRequest',
    CustomOfferService
];