require('./angular-1/angular-app');
require('./js/appAdmin');
require('sortablejs');

// dev-pages
// 
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(function() {
    $('[test-notifications]').unbind('click').bind('click', function() {
        var types = ['success', 'danger', 'warning'];
        var icons = ['check-circle-outline', 'close-circle-outline', 'information-outline'];
        var texts = [
            'Lorem ipsum dolor sit amet.',
            'Lorem ipsum dolor sit amet, consectetur.',
            'Lorem ipsum dolor sit amet, consectetur adipisicing.'
        ];

        var type = _.random(0, types.length - 1);

        pushNotifications.push(
            types[type],
            icons[type],
            texts[_.random(0, texts.length - 1)],
            5000
        );
    });

    $('[toggle-sidebar]').unbind('click').bind('click', function(e) {
        e && e.preventDefault() && e.stopPropagation();

        var $body = $('body');

        if ($body.hasClass('sidebar-opened')) {
            $body.removeClass('sidebar-opened');
        } else {
            $body.addClass('sidebar-opened');
        }
    });

    $('[toggle-navbar]').unbind('click').bind('click', function(e) {
        e && e.preventDefault() && e.stopPropagation();

        var $body = $('body');

        if ($body.hasClass('navbar-opened')) {
            $body.removeClass('navbar-opened');
        } else {
            $body.addClass('navbar-opened');
        }
    });

    $('.block-aside .menu .menu-item .submenu-toggle').unbind().bind('click', function(e) {
        e && e.preventDefault() & e.stopPropagation();

        $(this).parent().closest('.menu-item').toggleClass('open');
        $('.block-aside .menu').nanoScroller();
    });

    $('.block-layout .menu-submenu .menu-item.active').each(function() {
        var target_parent = $(this).parent().closest('.menu-item');

        if (!target_parent.hasClass('open'))
            target_parent.find('.submenu-toggle').click();
    });
});

(function($) {
    $.prototype.checkboxSelector = function() {
        var self = $(this);
        var boxes = [];

        if (self.length === 0)
            return;

        var boxItem = function(box) {
            var _self = this;

            var input = box.find('[type="checkbox"]')[0];

            _self.init = function() {
                _self.bind();

                if (input.checked)
                    box.addClass('checked');
            };

            _self.bind = function() {
                box.unbind('click').bind('click', function() {
                    box.toggleClass('checked');
                    input.checked = box.hasClass('checked');
                });
            };

            _self.init();
        };

        self.each(function(_ai, _av) {
            boxes.push(new boxItem($(_av)));
        });
    };

    $.prototype.radioSelector = function() {
        var self = $(this);
        var radios = [];

        if (self.length === 0)
            return;

        var radioItem = function(radio) {
            var _self = this;

            var input = radio.find('[type="radio"]')[0];

            var sib = $('input[name="' + input.name + '"]');

            _self.init = function() {
                _self.bind();

                if (input.checked)
                    radio.addClass('checked');
            };

            _self.bind = function() {
                radio.unbind('click').bind('click', function() {
                    sib.removeClass('checked');
                    $(this).addClass('checked');

                    input.checked = radio.hasClass('checked');

                    sib.each(function(_ai, _av) {
                        if (_av.checked) {
                            $(_av).closest('[radio-selector]').addClass('checked');
                        } else {
                            $(_av).closest('[radio-selector]').removeClass('checked');
                        }
                    });
                });
            };

            _self.init();
        }

        self.each(function(_ai, _av) {
            radios.push(new radioItem($(_av)));
        });
    };

    $.prototype.mediaUploader = function() {
        var self = this;
        var selectors = [];

        var header = $('meta[name="csrf-token"]').attr('content');
        var types = ['image/png', 'image/jpg', 'image/jpeg'];

        if (self.length === 0)
            return;

        var mediaUploaderItem = function($node) {
            var _self = this;

            var trigger = $node.find('[trigger]');
            var preview = $node.find('[preview]');

            var image;
            var file_input;
            var input_name = 'image';
            var std = preview.attr('src');

            var _type = $node.attr('type');

            _type = _type ? _type : 'avatar';

            _self.init = function() {
                if ($node.attr('name'))
                    input_name = $node.attr('name');

                file_input = $('<input name="avatar" type="file" />');
                image = $('<input name="' + input_name + '" type="text" class="hidden" />');

                $node.append(image);

                if ($node.attr('old-value'))
                    _self.loadData($node.attr('old-value'));

                _self.bind();
            };

            _self.bind = function() {
                trigger.unbind('click').bind('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    file_input.click();
                });

                file_input.unbind('change').bind('change', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    preview.attr('src', std);
                    image.val('');

                    if (types.indexOf(e.target.files[0].type) != -1)
                        _self.doUpload(e.target.files[0]);

                    delete e.target.files;
                });
            };

            _self.doUpload = function(file) {
                var request = new XMLHttpRequest();
                var form_data = new FormData();

                form_data.append('image', file);
                form_data.append('mediable_type', $node.attr('mediable-type'));
                form_data.append('mediable_id', $node.attr('mediable-id'));
                form_data.append('type', $node.attr('type'));

                request.open('POST', '/media/single-upload/' + _type);
                request.setRequestHeader('X-CSRF-TOKEN', header);
                request.setRequestHeader('Accept', 'application/json');

                request.onload = function(data) {
                    data = JSON.parse(data.target.response);

                    image.val(data.mediaId);
                    preview.attr('src', data.dataUrl);
                }

                request.send(form_data);
            }

            _self.loadData = function(id) {
                var request = new XMLHttpRequest();
                var form_data = new FormData();

                form_data.append('id', id);

                request.open('POST', '/media/single-media-url-data');
                request.setRequestHeader('X-CSRF-TOKEN', header);

                request.onload = function(data) {
                    data = JSON.parse(data.target.response);

                    image.val(id);
                    preview.attr('src', data.dataUrl);
                }

                request.send(form_data);

            }

            _self.init();
        }

        $.each(self, function(_ai, _av) {
            selectors.push(new mediaUploaderItem($(_av)));
        });
    };

    $.prototype.radioInput = function() {
        var self = this;
        var _lists = [];

        if (self.length < 1)
            return;

        var update = function() {
            for (var i = _lists.length - 1; i >= 0; i--) {
                var _input = _lists[i].find('input[type="radio"]');

                if (_input[0].checked) {
                    _lists[i].attr('checked', true);
                } else {
                    _lists[i].removeAttr('checked');
                }

            };
        }

        var _checkbox = function(_self) {
            var input = _self.find('input[type="radio"]');

            _self.unbind('click').bind('click', function(e) {
                if (e.target == _self[0]) {
                    input.click();
                    update();
                }
            });

            return _self;
        }

        for (var i = self.length - 1; i >= 0; i--) {
            _lists.push(_checkbox($(self[i])));
        };

        update();
    };

    $.prototype.languageSwitcher = function(args) {
        var languageSwitcher = function($toggle) {
            var toggleVisibility = function($input) {
                $('[language-option]').addClass('hidden');
                $('[language-option=' + $input[0].value + ']').removeClass('hidden');
            };

            $toggle.unbind('change').bind('change', function() {
                toggleVisibility($(this));
            });

            toggleVisibility($toggle);
        };

        for (var i = this.length - 1; i >= 0; i--) {
            new languageSwitcher($(this[i]));
        }
    };

    $.prototype.radioTabs = function(conf) {
        conf = conf || {};

        conf.tab_selector = conf.tab_selector || 'radio-tabs-bind';
        conf.pane_selector = conf.pane_selector || 'radio-tabs-pane';

        var RadioTabs = function($root) {
            var tabs = {};
            var panes = {};

            var $tabs = $root.find('[' + conf.tab_selector + ']');
            var $panes = $root.find('[' + conf.pane_selector + ']');

            for (var i = $panes.length - 1; i >= 0; i--) {
                $($panes[i]).hide();
            }

            $tabs.unbind('click').bind('click', function(e) {
                var $input = $(e.target);
                var value = $input.attr(conf.tab_selector);

                if ($input.attr('type') == 'radio') {
                    $panes.filter('[' + conf.pane_selector + '^="' + value.split('|')[0] + '|"]').hide();
                    $panes.filter('[' + conf.pane_selector + '="' + value + '"]').show();
                } else {
                    var $inputs = $root.find('[' + conf.tab_selector + '^="' + value.split('|')[0] + '|"]');

                    $inputs.each(function() {
                        var $input = $(this);
                        $pane = $root.find('[' + conf.pane_selector + '="' + $input.attr(conf.tab_selector) + '"]');

                        if ($input.is(':checked')) {
                            $pane.show();
                        } else {
                            $pane.hide();
                        }
                    });
                }
            });

            for (var i = $tabs.length - 1; i >= 0; i--) {
                if ($tabs[i].type == 'radio' && $($tabs[i]).is(':checked')) {
                    $($tabs[i]).click();
                } else {
                    $($tabs[i]).click();
                    $($tabs[i]).click();
                }
            }
        }

        for (var i = this.length - 1; i >= 0; i--) {
            new RadioTabs($(this[i]));
        }
    };

    $('[language-switcher]').languageSwitcher();
    $('[checkbox-selector]').checkboxSelector();
    $('[radio-selector]').radioSelector();
    $('[media-uploader]').mediaUploader();
    $('label.radio').radioInput();
    $('.nano').nanoScroller();
    $('body').radioTabs();
})(jQuery);

window.pushNotifications = new(function($node) {
    $node = $('[push-notifications]');

    var self = this;
    var notes = $node.find('.notification');
    var template = $node.find('.template');
    var shownNotes = [];
    var maxCount = 4;

    self.bind = function($note) {
        if (!$note.hasClass('shown')) {
            $note.addClass('shown');
        }

        var note = {
            note: $note
        };

        note.timeout = setTimeout(function() {
            self.deleteNotification(note);
        }, parseInt(note.note.attr('expire')) * 1);

        shownNotes.push(note);

        note.note.find('.close').unbind('click').bind('click', function() {
            self.deleteNotification(note);
        });

        if (shownNotes.length > maxCount) {
            var _note = shownNotes[0];

            self.deleteNotification(_note);
        }
    };

    self.deleteNotification = function(note) {
        clearTimeout(note.timeout);
        note.note.removeClass('shown');
        shownNotes.splice(shownNotes.indexOf(note), 1);

        setTimeout(function() {
            note.note.remove();
        }, 450);
    };

    self.init = function() {
        notes.each(function(index, note) {
            self.bind($(note));
        });
    };

    self.push = function(type, icon, text, timeout) {
        var notification = template.clone();

        notification.removeClass('template');
        notification.addClass('notification');
        notification.addClass('notification-' + type);

        notification.removeAttr('hidden');
        notification.attr('expire', timeout);
        notification.find('.text').text(text);
        notification.find('.icon').addClass('mdi mdi-' + icon);

        $node.find('.inner').prepend(notification);

        setTimeout(function() {
            self.bind(notification);
        }, 100);
    };

    self.init();
})();