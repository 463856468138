let AgendaBookingsCard = function(
    $scope,
    $element
) {
    $scope.viewIndex = 0;

    $scope.updateAppointment = () => {
        $scope.appointment = $scope.appointments[$scope.viewIndex];
    };

    $scope.cleanup = () => {
        angular.element('body').unbind('keydown.agendaAppointmentsCard');
    };

    $scope.nextItem = () => {
        if (++$scope.viewIndex >= $scope.appointments.length) {
            $scope.viewIndex = 0;
        }
    };

    $scope.prevItem = () => {
        if (--$scope.viewIndex < 0) {
            $scope.viewIndex = $scope.appointments.length - 1;
        }
    };

    $scope.$watch('viewIndex', function() {
        $scope.updateAppointment();
    });

    $scope.$on('$destroy', function() {
        $scope.cleanup();
    });

    setTimeout(() => {
        angular.element('body').bind('keydown.agendaAppointmentsCard', function(e) {
            $scope.$apply(function() {
                if (e.which === 37) {
                    $scope.prevItem();
                } else if (e.which === 39) {
                    $scope.nextItem();
                }
            });
        });
    }, 100);
};

module.exports = () => {
    return {
        scope: {
            appointments: '='
        },
        controller: [
            '$scope',
            '$element',
            AgendaBookingsCard
        ],
        templateUrl: '/assets/panel/tpl/directives/agenda/agenda-bookings-card.html',
    }
};