let ModalBookingCustomItemEditComponent = function(
    ValidateService,
    FormBuilderService
) {
    let $ctrl = this;
    let scope;

    $ctrl.errorKeyPreset = 'activities';
    $ctrl.errorKey;

    $ctrl.$onInit = () => {
        scope = $ctrl.modal.scope;

        $ctrl.activities = scope.models.activities;
        $ctrl.errorKey = [$ctrl.errorKeyPreset, scope.index].join('.') + '.';

        $ctrl.form = FormBuilderService.build(scope.entity, form => {
            let promise;

            if (form.values.id) {
                promise = ValidateService.updateBookingCustomItem(
                    form.values.id, 
                    form.values
                );
            } else {
                promise = ValidateService.storeBookingCustomItem(form.values);
            }

            promise.then((res) => {
                $ctrl.modal.scope.submit({
                    data: null,
                    entity: scope.entity
                });
                $ctrl.close();
            }, res => {
                form.errors = res.data.errors;
                form.unlock();
            });
        }, true);
    };


    $ctrl.$onDestroy = () => {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'ValidateService',
        'FormBuilderService',
        ModalBookingCustomItemEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-booking-custom-item-edit.html';
    }
};