let ModalGiftCardAccommodationEditComponent = function(
    ValidateService,
    FormBuilderService
) {
    let $ctrl = this;

    $ctrl.form = false;
    $ctrl.custom_item = false;
    $ctrl.accommodationModel = false;

    $ctrl.updateAccommodationModel = () => {
        $ctrl.accommodationModel = $ctrl.accommodations.filter(
            item => item.id == $ctrl.form.values.accommodation_id
        )[0];
    };

    $ctrl.getSelectedOptions = (form) => {
        let accommodation_options = [];
        let options = form.accommodation_options[
            form.values.accommodation_id
        ] || {};

        console.log(accommodation_options, options, form);

        for (const key in options) {
            if (options[key]) {
                accommodation_options.push(key);
            }
        }

        return accommodation_options;
    };

    $ctrl.$onInit = () => {
        $ctrl.types = $ctrl.modal.scope.types;
        $ctrl.isUsed = $ctrl.modal.scope.isUsed;
        $ctrl.accommodations = $ctrl.modal.scope.accommodations;
        $ctrl.accommodation = JSON.parse(JSON.stringify(
            $ctrl.modal.scope.accommodation
        ));

        if ($ctrl.accommodation.amount) {
            $ctrl.accommodation.amount = parseFloat($ctrl.accommodation.amount);
        }

        $ctrl.form = FormBuilderService.build($ctrl.accommodation, form => {
            let promise;
            let values = Object.assign(JSON.parse(JSON.stringify(form.values)), {
                accommodation_options: $ctrl.getSelectedOptions(form)
            });

            if (form.values.id) {
                promise = ValidateService.updateGiftCardAccommodation(form.values.id, values);
            } else {
                promise = ValidateService.storeGiftCardAccommodation(values);
            }

            values.accommodation = $ctrl.accommodationModel;

            promise.then((res) => {
                $ctrl.modal.scope.submit(values);
                $ctrl.close();
            }, res => {
                form.errors = res.data.errors;
                form.unlock();
            });
        }, true);

        let accommodation_options = {};

        accommodation_options[$ctrl.form.values.accommodation_id] = {};

        $ctrl.accommodation.accommodation_options.forEach(accommodation_option => {
            accommodation_options[
                $ctrl.accommodation.accommodation_id
            ][accommodation_option] = true;
        });
        
        $ctrl.form.accommodation_options = accommodation_options;
        $ctrl.updateAccommodationModel();
    };


    $ctrl.$onDestroy = () => {};
};

module.exports = {
    bindings: {
        close: '=',
        modal: '='
    },
    controller: [
        'ValidateService',
        'FormBuilderService',
        ModalGiftCardAccommodationEditComponent
    ],
    templateUrl: () => {
        return '/assets/panel/tpl/modals/modal-gift-card-accommodation-item-edit.html';
    }
};